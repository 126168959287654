import Decimal from 'decimal.js';
import { z } from 'zod';

// Only validates format NOT whether it's a valid date on the calendar
export const SIMPLE_DATE_REGEX = new RegExp('^\\d{4}-\\d{2}-\\d{2}$');

// https://regex101.com/r/69RUbB/1
export const PHONE_NUMBER_REGEX =
  // Contains 4 capture groups: 1-3 digit country code, 3 digit area code, 3 digit prefix, 4 digit suffix
  new RegExp(
    '^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$'
  );

export const zIsoDate = () => z.string().regex(SIMPLE_DATE_REGEX);

export const zDate = () => z.date().or(z.string());

/**
 * Boolean or coerce a string to a boolean. If the string is not a valid boolean, it will throw an error.
 * See: https://github.com/colinhacks/zod/issues/2985
 */
export const zCoercedBoolean = () =>
  z.boolean().or(
    z
      .string()
      .refine(
        (value) => {
          const lowerValue = value.toLowerCase();
          return lowerValue === 'true' || lowerValue === 'false';
        },
        {
          message:
            'Value must be boolean or a string representation of a boolean, e.g. true or false',
        }
      )
      .transform((value) => {
        const lowerValue = value.toLowerCase();
        return lowerValue === 'true';
      })
  );

export const zPhoneNumber = () => z.string().regex(PHONE_NUMBER_REGEX);

export const zDecimal = () => z.instanceof(Decimal);

export const zDecimalNullable = () => {
  return z
    .instanceof(Decimal)
    .or(z.string())
    .or(z.number())
    .nullable()
    .transform((val, ctx) => {
      if (val === null) {
        return val;
      }

      if (typeof val === 'string' && val.trim() === '') {
        return null;
      }

      return transformDecimal(val, ctx);
    });
};

export const zDecimalRequired = () => {
  return z
    .instanceof(Decimal)
    .or(z.string())
    .or(z.number())
    .transform(transformDecimal);
};

function transformDecimal(
  val: Decimal | string | number | null | undefined,
  ctx: z.RefinementCtx
) {
  if (val instanceof Decimal) {
    return val;
  }

  if (typeof val === 'string') {
    try {
      return new Decimal(val);
    } catch (e) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ctx.path,
        message: `For ${ctx.path.join(
          '.'
        )} string '${val}' is not convertible to Decimal`,
      });
      return z.NEVER;
    }
  }

  if (typeof val === 'number') {
    return new Decimal(val);
  }

  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    path: ctx.path,
    message: `For ${ctx.path.join(
      '.'
    )} expected a string, number or Decimal but received ${val}`,
  });

  return z.NEVER;
}
