import { z } from 'zod';
import {
  AipNames,
  InsureIQPolicyTypes,
  PolicyStatuses,
} from '@harvestiq/constants';
import {
  GeneratedAlways,
  Generated,
  Selectable,
  Insertable,
  Updateable,
} from 'kysely';
import { PolicyData, policyDataSchema } from './policy-data';
import { zIsoDate } from '@harvestiq/zod';

export interface PoliciesTable {
  id: GeneratedAlways<string>;
  name: string;
  type: InsureIQPolicyTypes;
  status: PolicyStatuses;
  data: PolicyData;
  previousPolicyId: string | null;
  previousPolicyProvider: string | null;
  insurerName: AipNames | null;
  insurerPolicyId: string | null;
  isExternallyManaged: boolean;
  customerSignedAt: Date | null;
  agentSignedAt: Date | null;
  externalStatus: string | null;
  reinsuranceYear: number;
  entityId: string | null;
  conditionsOfAcceptanceA: boolean | null;
  conditionsOfAcceptanceB: boolean | null;
  conditionsOfAcceptanceC: boolean | null;
  conditionsOfAcceptanceD: boolean | null;
  conditionsOfAcceptanceE: boolean | null;
  conditionsOfAcceptanceF: boolean | null;
  applicantDob: string | null; // isoDate string
  applicantIsEighteen: boolean | null;
  createdAt: GeneratedAlways<Date>;
  updatedAt: Generated<Date>;
  deletedAt: Date | null;
}

export type DbPolicy = Selectable<PoliciesTable>;
export const dbPolicySchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.nativeEnum(InsureIQPolicyTypes),
  status: z.nativeEnum(PolicyStatuses),
  data: policyDataSchema,
  previousPolicyId: z.string().nullable(),
  previousPolicyProvider: z.string().nullable(),
  insurerName: z.nativeEnum(AipNames).nullable(),
  insurerPolicyId: z.string().nullable(),
  isExternallyManaged: z.boolean(),
  customerSignedAt: z.date().nullable(),
  agentSignedAt: z.date().nullable(),
  externalStatus: z.string().nullable(),
  reinsuranceYear: z.number(),
  entityId: z.string().nullable(),
  conditionsOfAcceptanceA: z.boolean().nullable(),
  conditionsOfAcceptanceB: z.boolean().nullable(),
  conditionsOfAcceptanceC: z.boolean().nullable(),
  conditionsOfAcceptanceD: z.boolean().nullable(),
  conditionsOfAcceptanceE: z.boolean().nullable(),
  conditionsOfAcceptanceF: z.boolean().nullable(),
  applicantDob: zIsoDate().nullable(),
  applicantIsEighteen: z.boolean().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
});

export type DbPolicyInsert = Insertable<PoliciesTable>;
export const dbPolicyInsertSchema = dbPolicySchema.omit({
  id: true,
  createdAt: true,
});

export type DbPolicyUpdate = Updateable<PoliciesTable>;
export const dbPolicyUpdateSchema = dbPolicySchema
  .omit({
    id: true,
    createdAt: true,
  })
  .partial();
