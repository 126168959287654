import {
  ColumnType,
  GeneratedAlways,
  Insertable,
  Selectable,
  Updateable,
} from 'kysely';
import { Decimal } from 'decimal.js';
import { expectTypeOf } from 'expect-type';
import { z } from 'zod';
import { zDecimal, zDecimalRequired } from '@harvestiq/zod';
import {
  RmaClassCodes,
  RmaCommodityCodes,
  RmaCommodityTypeCodes,
  RmaInsurancePlanCodes,
  RmaSubclassCodes,
  RmaTypeCodes,
} from '@harvestiq/constants';

/**
 * Types taken from RMA ADM Layout
 * See: https://pubfs-rma.fpac.usda.gov/pub/References/actuarial_data_master/2025/ADMLayout.zip
 */
export interface DbLrpRateDailyTable {
  id: GeneratedAlways<string>;
  createdAt: ColumnType<Date, never, never>;
  updatedAt: ColumnType<Date, never, Date>;
  recordTypeCode: ColumnType<string, string, string | null>;
  recordCategoryCode: ColumnType<string, string, string | null>;
  reinsuranceYear: ColumnType<number, number, number | null>;
  commodityYear: ColumnType<number, number, number | null>;
  commodityCode: ColumnType<
    RmaCommodityCodes,
    RmaCommodityCodes,
    RmaCommodityCodes | null
  >;
  insurancePlanCode: ColumnType<
    RmaInsurancePlanCodes,
    RmaInsurancePlanCodes,
    RmaInsurancePlanCodes | null
  >;
  stateCode: ColumnType<string, string, string | null>;
  countyCode: ColumnType<string, string, string | null>;
  typeCode: ColumnType<RmaTypeCodes, RmaTypeCodes, RmaTypeCodes | null>;
  practiceCode: ColumnType<string, string, string | null>;
  salesEffectiveDate: ColumnType<string, Date | string, Date | string>;
  endorsementLengthCount: ColumnType<number, number, number | null>;
  coveragePrice: ColumnType<Decimal, number, number | null>;
  commodityTypeCode: ColumnType<
    RmaCommodityTypeCodes,
    RmaCommodityTypeCodes,
    RmaCommodityTypeCodes | null
  >;
  classCode: ColumnType<RmaClassCodes, RmaClassCodes, RmaClassCodes | null>;
  subClassCode: ColumnType<
    RmaSubclassCodes,
    RmaSubclassCodes,
    RmaSubclassCodes | null
  >;
  intendedUseCode: ColumnType<string, string, string | null>;
  irrigationPracticeCode: ColumnType<string, string, string | null>;
  croppingPracticeCode: ColumnType<string, string, string | null>;
  organicPracticeCode: ColumnType<string, string, string | null>;
  intervalCode: ColumnType<string, string, string | null>;
  endorsementLengthCode: ColumnType<string, string, string | null>;
  targetLowWeight: ColumnType<Decimal, number, number | null>;
  targetHighWeight: ColumnType<Decimal, number, number | null>;
  expectedEndingValueAmount: ColumnType<Decimal, number, number | null>;
  livestockCoverageLevelPercent: ColumnType<Decimal, number, number | null>;
  livestockRate: ColumnType<Decimal, number, number | null>;
  costPerCwtAmount: ColumnType<Decimal, number, number | null>;
  rmaNoteNumber: ColumnType<number, number, number | null>;
  endDate: ColumnType<string, Date | string, Date | string | null>;
  priceAdjustmentFactor: ColumnType<
    string | null,
    string | null,
    string | null
  >;
  releasedDate: ColumnType<string, Date | string, Date | string | null>;
  lastReleasedDate: ColumnType<
    string | null,
    Date | string | null,
    Date | string | null
  >;
  deletedDate: ColumnType<
    string | null,
    Date | string | null,
    Date | string | null
  >;
  filingDate: ColumnType<string, Date | string, Date | string | null>;
}

export type DbLrpRateDaily = Selectable<DbLrpRateDailyTable>;
export type DbLrpRateDailyInsert = Insertable<DbLrpRateDailyTable>;
export type DbLrpRateDailyUpdate = Updateable<DbLrpRateDailyTable>;

export const dbLrpRateDailySchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  recordTypeCode: z.string(),
  recordCategoryCode: z.string(),
  reinsuranceYear: z.number(),
  commodityYear: z.number(),
  commodityCode: z.nativeEnum(RmaCommodityCodes),
  insurancePlanCode: z.nativeEnum(RmaInsurancePlanCodes),
  stateCode: z.string(),
  countyCode: z.string(),
  typeCode: z.nativeEnum(RmaTypeCodes),
  practiceCode: z.string(),
  salesEffectiveDate: z.string(),
  endorsementLengthCount: z.number(),
  coveragePrice: zDecimalRequired(),
  commodityTypeCode: z.nativeEnum(RmaCommodityTypeCodes),
  classCode: z.nativeEnum(RmaClassCodes),
  subClassCode: z.nativeEnum(RmaSubclassCodes),
  intendedUseCode: z.string(),
  irrigationPracticeCode: z.string(),
  croppingPracticeCode: z.string(),
  organicPracticeCode: z.string(),
  intervalCode: z.string(),
  endorsementLengthCode: z.string(),
  targetLowWeight: zDecimalRequired(),
  targetHighWeight: zDecimalRequired(),
  expectedEndingValueAmount: zDecimalRequired(),
  livestockCoverageLevelPercent: zDecimalRequired(),
  livestockRate: zDecimalRequired(),
  costPerCwtAmount: zDecimalRequired(),
  rmaNoteNumber: z.number(),
  endDate: z.string(),
  priceAdjustmentFactor: z.string().nullable(),
  releasedDate: z.string(),
  lastReleasedDate: z.string().nullable(),
  deletedDate: z.string().nullable(),
  filingDate: z.string(),
});

export const lrpRateDailyInsertSchema = dbLrpRateDailySchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .extend({
    costPerCwtAmount: z.number(),
    expectedEndingValueAmount: z.number(),
    coveragePrice: z.number(),
    livestockCoverageLevelPercent: z.number(),
    livestockRate: z.number(),
  });

export const lrpRateDailyUpdateSchema = dbLrpRateDailySchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    costPerCwtAmount: z.number(),
    expectedEndingValueAmount: z.number(),
    coveragePrice: z.number(),
    livestockCoverageLevelPercent: z.number(),
    livestockRate: z.number(),
  })
  .partial();

// Verify derived type matches kysely
expectTypeOf<DbLrpRateDaily>().toEqualTypeOf<
  z.infer<typeof dbLrpRateDailySchema>
>();
