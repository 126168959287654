import React, { useCallback, useState } from 'react'
import { Button, List, Collapse, ListItemButton, Popover } from '@mui/material'
import classNames from 'classnames'
import { slugify } from 'common'
import { NavLink, matchPath, useLocation } from 'react-router-dom'
import { useThemeManager } from '../../contexts'

const linkStyle = ({ isActive, theme, links, location }) => {
  let isLinkActive = isActive

  // this activate just the correct parent menu item when childs have the same base pathname but are in different menus
  // (e.g. /insurance/lrp-quote in Quotes and /insurance/my-coverages in Coverages)
  if (links && location) {
    isLinkActive = links.some(link => matchPath(location.pathname, link))
  }

  return {
    color: isLinkActive
      ? theme.palette.getContrastText(theme.palette.primary.light)
      : theme.palette.primary.main,
    backgroundColor: isLinkActive ? theme.palette.primary.light : 'transparent',
  }
}

export default function Menu({
  items,
  id = 'menu',
  menuItemClassName,
  variant = 'text',
  ...menuProps
}) {
  const { theme } = useThemeManager()
  const location = useLocation()
  const [anchorEls, setAnchorEls] = useState({})

  const setAnchorEl = useCallback(
    (elId, el = null) => setAnchorEls({ ...anchorEls, [elId]: el }),
    [anchorEls],
  )

  const handleAnchorSet = useCallback(
    event => setAnchorEl(event.currentTarget.id, event.currentTarget),
    [setAnchorEl],
  )

  const handleClose = useCallback(elId => setAnchorEl(elId), [setAnchorEl])
  const handleClick = useCallback((evt, { onClick }) => onClick?.(evt), [])

  const getMenuList = item => {
    const { title, label = title, children, className, path, links } = item
    const menuItemId = slugify(`${id}-${label}`)

    return [
      <Button
        key={menuItemId}
        aria-controls={menuItemId}
        aria-haspopup="true"
        id={menuItemId}
        onClick={children ? handleAnchorSet : evt => handleClick(evt, item)}
        className={classNames('p0', menuItemClassName, className)}
        variant={variant}
      >
        {path ? (
          <NavLink
            to={path}
            className="py_25 px1 br_25"
            style={({ isActive }) =>
              linkStyle({ theme, isActive, links, location })
            }
          >
            {label}
          </NavLink>
        ) : (
          <span
            className="py_25 px1"
            style={{ color: theme.palette.primary.main }}
          >
            {label}
          </span>
        )}
      </Button>,
      children && (
        <Popover
          key={`${menuItemId}-Popover`}
          open={Boolean(anchorEls[menuItemId])}
          anchorEl={anchorEls[menuItemId]}
          onClose={() => handleClose(menuItemId)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {getMenuListItems(children, menuItemId)}
        </Popover>
      ),
    ]
  }

  const getMenuListItems = (listItems, parentId) => {
    return (
      <List
        component="div"
        disablePadding
        onMouseLeave={() => setAnchorEls({})}
      >
        {listItems.map(item => {
          const { title, label = title, path, children } = item
          const menuListId = slugify(`${parentId}-${label}`)

          return [
            <ListItemButton
              key={menuListId}
              id={menuListId}
              onClick={
                children ? handleAnchorSet : evt => handleClick(evt, item)
              }
              onMouseEnter={handleAnchorSet}
              className="p0 block"
            >
              {path ? (
                <NavLink
                  to={path}
                  className="block py_5 px1"
                  style={({ isActive }) => linkStyle({ theme, isActive })}
                >
                  {label}
                </NavLink>
              ) : (
                label
              )}
            </ListItemButton>,
            children && (
              <Collapse
                key={`${menuListId}-Collapse`}
                in={Boolean(anchorEls[menuListId])}
              >
                {getMenuListItems(children, menuListId)}
              </Collapse>
            ),
          ]
        })}
      </List>
    )
  }

  return <div {...menuProps}>{items?.map(item => getMenuList(item))}</div>
}
