import Decimal from 'decimal.js';
import { RmaTypeCodes } from './rma/TypeCodes';
import { UnitOfMeasure } from './UOM';

export enum LgmTargetWeights {
  LIVE_CATTLE = 'Target Live Cattle Weight',
  FEEDER_CATTLE = 'Target Feeder Cattle Weight',
  CORN = 'Target Corn Weight',
  LEAN_HOG = 'Target Lean Hog Weight',
  SOYBEAN_MEAL = 'Target Soybean Meal Weight',
}

export type LgmCattleTargetWeightInfo = {
  min: Decimal;
  max: Decimal;
  default: Decimal;
  uom: UnitOfMeasure;
};

export type LgmSwineTargetWeightInfo = {
  default: Decimal;
  uom: UnitOfMeasure;
};

export const LgmSwineLeanHogFactor = new Decimal(0.74);

// @todo: change string type to RmaTypeCodes once all are updated
export const LgmCattleTargetWeightsByCommodityTypeCode: Record<
  string,
  Record<'liveCattle' | 'feederCattle' | 'corn', LgmCattleTargetWeightInfo>
> = {
  [RmaTypeCodes.CALF_FINISHING]: {
    liveCattle: {
      min: new Decimal(11),
      max: new Decimal(13),
      default: new Decimal(new Decimal(11.5)),
      uom: UnitOfMeasure.HundredWeight,
    },
    feederCattle: {
      min: new Decimal(4),
      max: new Decimal(6),
      default: new Decimal(5.5),
      uom: UnitOfMeasure.HundredWeight,
    },
    corn: {
      min: new Decimal(50),
      max: new Decimal(75),
      default: new Decimal(52),
      uom: UnitOfMeasure.Bushel,
    },
  },
  [RmaTypeCodes.YEARLING_FINISHING]: {
    liveCattle: {
      min: new Decimal(12),
      max: new Decimal(15),
      default: new Decimal(12.5),
      uom: UnitOfMeasure.HundredWeight,
    },
    feederCattle: {
      min: new Decimal(6),
      max: new Decimal(9),
      default: new Decimal(7.5),
      uom: UnitOfMeasure.HundredWeight,
    },
    corn: {
      min: new Decimal(50),
      max: new Decimal(85),
      default: new Decimal(50),
      uom: UnitOfMeasure.Bushel,
    },
  },
};

export const LgmSwineTargetWeightsByCommodityTypeCode: Record<
  | RmaTypeCodes.FARROW_TO_FINISH
  | RmaTypeCodes.FINISHING
  | RmaTypeCodes.SEW_PIG_FINISHING,
  Record<'leanHog' | 'corn' | 'soybeanMeal', LgmSwineTargetWeightInfo>
> = {
  [RmaTypeCodes.FARROW_TO_FINISH]: {
    leanHog: {
      default: new Decimal(2.6),
      uom: UnitOfMeasure.HundredWeight,
    },
    soybeanMeal: {
      default: new Decimal(0.069275),
      uom: UnitOfMeasure.ShortTon,
    },
    corn: {
      default: new Decimal(12),
      uom: UnitOfMeasure.Bushel,
    },
  },
  [RmaTypeCodes.FINISHING]: {
    leanHog: {
      default: new Decimal(2.6),
      uom: UnitOfMeasure.HundredWeight,
    },
    soybeanMeal: {
      default: new Decimal(0.041),
      uom: UnitOfMeasure.ShortTon,
    },
    corn: {
      default: new Decimal(9),
      uom: UnitOfMeasure.Bushel,
    },
  },
  [RmaTypeCodes.SEW_PIG_FINISHING]: {
    leanHog: {
      default: new Decimal(2.6),
      uom: UnitOfMeasure.HundredWeight,
    },
    soybeanMeal: {
      default: new Decimal(0.0455),
      uom: UnitOfMeasure.ShortTon,
    },
    corn: {
      default: new Decimal(9.05),
      uom: UnitOfMeasure.Bushel,
    },
  },
};
