import { z } from 'zod';
import { dbLrpEndorsementSchema } from './db-lrp-endorsement';
import { dbLgmEndorsementSchema } from './db-lgm-endorsement';

export const getCoverageResponseSchema = z.object({
  coverage: z.discriminatedUnion('type', [
    dbLrpEndorsementSchema.extend({
      ownerUserId: z.string().nullable(),
    }),
    dbLgmEndorsementSchema.extend({
      ownerUserId: z.string().nullable(),
    }),
  ]),
});
export type GetCoverageResponse = z.infer<typeof getCoverageResponseSchema>;
