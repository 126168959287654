import { z } from 'zod';
import { zDecimalRequired } from '@harvestiq/zod';
import { dbLrpEndorsementSchema } from './db-lrp-endorsement';
import { dbLgmEndorsementSchema } from './db-lgm-endorsement';

export const insureIQEmailCoveragesReportRequestSchema = z.object({
  ccMe: z.boolean(),
  emailNotes: z.string(),
  s3FileKey: z.string(),
  recipients: z.array(
    z.object({
      email: z.string().email(),
      name: z.string(),
    })
  ),
  sendPreview: z.boolean().optional(),
});

export type InsureIQEmailCoveragesReportRequest = z.infer<
  typeof insureIQEmailCoveragesReportRequestSchema
>;

export const insureIQEmailCoveragesReportResponseSchema = z.object({
  emailNotes: z.string(),
  s3FileKey: z.string(),
  fileUrl: z.string(),
});

export type InsureIQEmailCoveragesReportResponse = z.infer<
  typeof insureIQEmailCoveragesReportResponseSchema
>;

export const coveragesDetailedWorksheetSchema = z.object({
  commodity: z.string(),
  numberofHead: z.number(),
  totalTargetWeight: z.number(),
  salesEffectiveDate: z.string(),
  endorsementLength: z.number(),
  endDate: z.string(),
  totalCoveragePrice: z.number(),
  totalPremium: z.number(),
  totalNetCoveragePrice: z.number(),
  totalProjectedOrActualRevenue: z.number().nullable(),
  totalProbableIndemnity: z.number().nullable(),
  totalNetProbableIndemnity: z.number().nullable(),
});

export type CoveragesDetailedWorksheet = z.infer<
  typeof coveragesDetailedWorksheetSchema
>;
export const coveragesAggregatedWorksheetSchema =
  coveragesDetailedWorksheetSchema
    .pick({
      commodity: true,
      numberofHead: true,
      totalTargetWeight: true,
      totalCoveragePrice: true,
      totalPremium: true,
      totalNetCoveragePrice: true,
      totalProjectedOrActualRevenue: true,
      totalProbableIndemnity: true,
      totalNetProbableIndemnity: true,
    })
    .extend({
      endMonth: z.string(),
      numberOfEndorsements: z.number().optional(),
    });

export type CoveragesAggregatedWorksheet = z.infer<
  typeof coveragesAggregatedWorksheetSchema
>;

export const coveragesReportKpisSchema = z.object({
  totalEndorsements: zDecimalRequired(),
  totalHeadcount: zDecimalRequired(),
  totalWeightCwt: zDecimalRequired(),
  totalInsuredValue: zDecimalRequired(),
  totalProjectedRevenue: zDecimalRequired(),
  totalProbableIndemnity: zDecimalRequired(),
  totalProducerPremimum: zDecimalRequired(),
  totalProbableNetIndemnity: zDecimalRequired(),
});

export type CoveragesReportKpis = z.infer<typeof coveragesReportKpisSchema>;

export const getLrpCoveragesReportResponseSchema = z.object({
  kpis: coveragesReportKpisSchema,
  s3FileKey: z.string(),
});
export type GetLrpCoveragesReportResponse = z.infer<
  typeof getLrpCoveragesReportResponseSchema
>;

export const getLgmCoveragesReportResponseSchema = z.object({
  kpis: coveragesReportKpisSchema,
  s3FileKey: z.string(),
});
export type GetLgmCoveragesReportResponse = z.infer<
  typeof getLgmCoveragesReportResponseSchema
>;
