import { ReactComponent as Bars } from '../../assets/images/loading-indicators/bars.svg'
import { ReactComponent as Clock } from '../../assets/images/loading-indicators/clock.svg'
import { ReactComponent as Eclipse } from '../../assets/images/loading-indicators/eclipse.svg'
import { ReactComponent as Pulse } from '../../assets/images/loading-indicators/pulse.svg'
import { ReactComponent as Ring } from '../../assets/images/loading-indicators/ring.svg'

export const loadingIndicatorTypeMap = {
  bars: Bars,
  clock: Clock,
  eclipse: Eclipse,
  pulse: Pulse,
  ring: Ring,
}

export const loadingIndicatorTypes = Object.keys(loadingIndicatorTypeMap)
const defaultIndicatorStyle = 'ring'

// BaseLoadingIndicator should be used when we do not have appSettings loaded yet
export const BaseLoadingIndicator = ({
  indicatorStyle = defaultIndicatorStyle,
  size = 48,
  color,
}) => {
  const Indicator = loadingIndicatorTypeMap[indicatorStyle]

  return (
    <span
      data-cy="loading-indicator"
      style={{
        width: size,
        height: size,
        color: color || 'currentColor',
      }}
    >
      <Indicator />
    </span>
  )
}
