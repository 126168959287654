import { useMemo } from 'react'
import { Box } from '@mui/material'
import classNames from 'classnames'
import { usePrevious } from 'react-use'
import { parseFieldProps } from 'common'

import Field from '../Field'
import Heading from '../FormHeading'
import { useAppSettings } from '../../contexts'

export const FieldGroup = ({
  fields,
  state,
  onChange,
  requiredFields: requiredFieldsProp = [],
  errors = {},
}) => {
  const { isMobile } = useAppSettings()
  const prevState = usePrevious(state)
  const fieldsArr = Object.entries(fields)
  const fieldCount = fieldsArr.length
  const groupClassNameField = fieldsArr.find(field => field[1].groupClassNames)
  const groupClassNames = groupClassNameField
    ? groupClassNameField[1].groupClassNames
    : 'flex flex-1 gap-2'

  let layoutClassName
  // Let the group class name override if present
  if (groupClassNameField) {
    layoutClassName = null
  } else if (isMobile && fieldCount > 1) {
    layoutClassName = 'flex-col'
  } else {
    layoutClassName = 'flex-row'
  }

  const requiredFields = useMemo(
    () =>
      typeof requiredFieldsProp === 'string'
        ? requiredFieldsProp.split('|')
        : requiredFieldsProp,
    [requiredFieldsProp],
  )

  return (
    <div className={classNames(layoutClassName, groupClassNames)}>
      {fieldsArr.map(([name, fieldType]) => {
        if (!fieldType) {
          return null
        }
        const flexBasis = Math.round(100 / fieldsArr.length)
        const isRequired = requiredFields.includes(name)
        const parsedFieldProps = parseFieldProps({
          field: fieldType,
          state,
          prevState,
        })

        if (!parsedFieldProps) {
          return null
        }

        const { type, className, ...additionalFieldProps } = parsedFieldProps

        const fieldProps = {
          type,
          name,
          value: state[name],
          required: isRequired,
          ...additionalFieldProps,
        }
        if (!['checkbox', 'slider'].includes(type)) {
          fieldProps.fullWidth = fieldCount > 1
        }
        if (type === 'checkbox') {
          fieldProps.CheckboxProps = {
            ...(fieldProps.CheckboxProps || {}),
            required: isRequired,
          }
        }

        if (type === 'heading') {
          return (
            <Heading
              key={`key-${name}`}
              name={name}
              {...additionalFieldProps}
            />
          )
        }
        // remove groupClassNames to avoid passing to children and causing errors
        delete fieldProps.groupClassNames
        return (
          <Box
            data-testid={name}
            key={`key-${name}`}
            className={
              className ||
              `flex gap-2 flex-basis-${parseFloat(flexBasis.toFixed(2), 10)}`
            }
          >
            <Field
              error={errors[name]}
              className="width-100"
              onChange={onChange}
              {...fieldProps}
            />
          </Box>
        )
      })}
    </div>
  )
}

export default FieldGroup
