import { z } from 'zod';
import { zPhoneNumber } from '@harvestiq/zod';
import {
  ApplicationTypes,
  ProgramIndicatorDescriptions,
} from '@harvestiq/constants';
import { livestockPolicyTypesSchema, livestockCapacitySchema } from './shared';
import { BusinessType } from '../companies/business-types';

const additionalSignerSchema = z.object({
  signer: z.string(),
});

const livestockLocationSchema = z.object({
  state: z.string().optional(),
  county: z.string().optional(),
  zip: z.string().min(5).max(10).optional(), // allow optional add-on (e.g. 12345-6789)
});
export type LivestockLocation = z.infer<typeof livestockLocationSchema>;

const sharedSbiSharesSchema = z.object({
  address: z.string(),
  addressNumber: z.string().optional(),
  applicationTypeOption: z.nativeEnum(ProgramIndicatorDescriptions),
  city: z.string().optional(),
  county: z.string().optional(),
  phone: zPhoneNumber(),
  shares: z.coerce.number().int().gt(0).lte(100), // Sometimes a string, sometimes a number
  state: z.string().length(2),
  zip: z.string().min(5).max(10), // allow optional add-on (e.g. 12345-6789)
});

const individualSbiSharesSchema = sharedSbiSharesSchema.extend({
  ownerType: z.literal('Individual'),
  addressLine1: z.string().optional(),
  email: z.string().email().optional(),
  firstName: z.string(),
  lastName: z.string(),
  middleName: z.string().optional(),
  // NOTE: This comes from insureiq/common spouseType
  // TODO: Fix this to use a @harvestiq/constants reference
  spouseType: z.enum(['applicant', 'non-applicant']).optional(),
  ssn: z.string().optional(),
  suffix: z.string().optional(),
  // static literal business fields, discriminated union types require all fields to be present
  businessName: z.undefined(),
  businessType: z.undefined(),
  ein: z.undefined(),
});

const businessSbiSharesSchema = sharedSbiSharesSchema.extend({
  ownerType: z.literal('Business'),
  businessName: z.string(),
  businessType: z.nativeEnum(BusinessType),
  ein: z.string(),
  // static literal individual fields, discriminated union types require all fields to be present
  addressLine1: z.undefined(),
  email: z.undefined(),
  firstName: z.undefined(),
  lastName: z.undefined(),
  middleName: z.undefined(),
  spouseType: z.undefined(),
  ssn: z.undefined(),
  suffix: z.undefined(),
});

const sbiSharesSchema = z.discriminatedUnion('ownerType', [
  individualSbiSharesSchema,
  businessSbiSharesSchema,
]);
export type SbiShares = z.infer<typeof sbiSharesSchema>;

const signatureSchema = z.object({
  fullName: z.string(),
  isAdditionalSigner: z.boolean(),
  signedAt: z.string().datetime().nullable(),
});

const spouseSchema = z.object({
  firstName: z.string(),
  middleName: z.string().optional(),
  lastName: z.string(),
  suffix: z.string().optional(),
  ssn: z.string().optional(),
  phone: zPhoneNumber(),
  email: z.string().email(),
  applicationTypeOption: z.nativeEnum(ProgramIndicatorDescriptions),
  address: z.string(),
  addressLine1: z.string().optional(),
  addressNumber: z.string().optional(),
  city: z.string(),
  county: z.string().optional(),
  state: z.string().length(2),
  zip: z.string().min(5).max(10), // allow optional add-on (e.g. 12345-6789)
});

const sharedPolicyDataSchema = z.object({
  additionalSigners: z.array(additionalSignerSchema).optional(),
  addressLine1: z.string().optional(),
  addressLine2: z.string().optional(),
  addressNumber: z.string().optional(),
  agentId: z.string().optional(),
  agentSignedAt: z.string().optional(),
  applicationOwnerType: z.enum(['Farmer', 'Landlord']).optional(),
  applicationTypeOption: z.nativeEnum(ProgramIndicatorDescriptions).optional(),
  city: z.string().optional(),
  cond1: z.boolean().optional(),
  cond2: z.boolean().optional(),
  cond3: z.boolean().optional(),
  cond4: z.boolean().optional(),
  cond5: z.boolean().optional(),
  cond6: z.boolean().optional(),
  contactEmail: z.string().email(),
  contactPhone: zPhoneNumber(),
  county: z.string().optional(),
  customerSignedAt: z.string().optional(),
  dob: z.string().optional(),
  email: z.string().email().optional(),
  hasAgency: z.boolean().optional(),
  isAccepted: z.boolean().optional(),
  isElectronicallySigned: z.boolean().optional(),
  isEighteen: z.boolean().optional(),
  livestock: livestockPolicyTypesSchema.optional(),
  livestockCapacity: livestockCapacitySchema.optional(),
  livestockLocation: livestockLocationSchema.optional(),
  maritalStatus: z.boolean().optional(),
  middleName: z.string().nullable().optional(),
  recipientName: z.string().optional(),
  shares: z.array(sbiSharesSchema),
  signatureFullName: z.string().optional(),
  signatures: z.array(signatureSchema),
  state: z.string().optional(),
  submittedToAipAt: z.string().optional(),
  timezone: z.string().optional(),
  zip: z.string().optional(),
});

export const individualPolicyDataSchema = sharedPolicyDataSchema.extend({
  applicationType: z.literal(ApplicationTypes.INDIVIDUAL),
  firstName: z.string(),
  lastName: z.string(),
  spouse: spouseSchema.or(z.object({})).optional(),
  ssn: z.string().optional(),
  suffix: z.string().optional(),
  // static literal business fields, discriminated union types require all fields to be present
  authorizedRep: z.undefined(),
  business: z.undefined(),
  businessType: z.undefined(),
  ein: z.undefined(),
  stateOfIncorporation: z.undefined(),
});

export const businessPolicyDataSchema = sharedPolicyDataSchema.extend({
  applicationType: z.literal(ApplicationTypes.BUSINESS),
  authorizedRep: z.string(),
  business: z.string(),
  businessType: z.nativeEnum(BusinessType),
  ein: z.string(),
  stateOfIncorporation: z.string().length(2),
  // Static literal individual fields, discriminated union types require all fields to be present
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  spouse: z.object({}).optional(),
  ssn: z.undefined(),
  suffix: z.undefined().nullable(),
});

export const policyDataSchema = z.discriminatedUnion('applicationType', [
  individualPolicyDataSchema,
  businessPolicyDataSchema,
]);
export type PolicyData = z.infer<typeof policyDataSchema>;
