import { createTheme } from '@mui/material/styles'
import { colorTypes, flatPalette } from 'common'

const defaultContrastThreshold = 2.3
export const formatColorPalette = (colorPalette = flatPalette) =>
  colorTypes.reduce(
    (acc, colorType) => ({
      ...acc,
      [colorType]: { main: colorPalette[colorType] },
    }),
    { contrastThreshold: defaultContrastThreshold },
  )

export const palette = formatColorPalette(flatPalette)

const typography = {
  h1: {
    fontSize: 36,
  },
  h2: {
    fontSize: 32,
  },
  h3: {
    fontSize: 28,
  },
  h4: {
    fontSize: 24,
  },
  h5: {
    fontSize: 22,
  },
  caption: {
    fontSize: 11,
  },
}

// Create a theme.
const theme = createTheme({
  palette,
  typography,
})

export const colorPickerColors = [
  '#525252',
  '#8F8F8F',
  '#E0E0E0',
  '#D85344',
  '#E08914',
  '#DEC414',
  '#C4C714',
  '#98C514',
  '#68B8B6',
  '#70C1E0',
  '#A095E0',
  '#DF95E0',
  '#3D3D3D',
  '#7B7B7B',
  '#B8B8B8',
  '#BD3C25',
  '#C97014',
  '#DEB114',
  '#A1AB14',
  '#68AB14',
  '#269898',
  '#1491C8',
  '#7764E0',
  '#DC34E0',
  '#141414',
  '#666666',
  '#A4A4A4',
  '#941814',
  '#B15514',
  '#DD9314',
  '#7B8214',
  '#28523D',
  '#1E7578',
  '#1463A2',
  '#653C8B',
  '#9D2493',
]
const chipColors = colorPickerColors.slice(15, -12)
export const getChipColor = index => chipColors[(index * 2) % chipColors.length]
export const alphabetPosition = input => parseInt(input, 36) - 10
export const prettyColor = input =>
  chipColors[alphabetPosition(input) % chipColors.length]

const paletteKeys = [...colorTypes, 'grey', 'action']
export const setCSSVariables = (
  obj,
  rootName = 'palette',
  keys = paletteKeys,
) => {
  keys.forEach(key => {
    const value = obj[key]
    const newRootName = rootName ? `${rootName}-${key}` : key
    if (typeof value === 'object') {
      return setCSSVariables(value, newRootName, Object.keys(value))
    }
    document.documentElement.style.setProperty(`--${newRootName}`, value)
  })
}

export default theme
