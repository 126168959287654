import { z } from 'zod';
import Decimal from 'decimal.js';
import { CommodityId } from './CommodityId';
import { InsureIQLrpCommodityNames } from './LrpInsurance';
import { UnitOfMeasure } from './UOM';
import { zDecimal } from '@harvestiq/zod';
import { RmaCommodityCodes } from './rma/CommodityCodes';

export const CommodityInformation = z.object({
  id: z.number().int().gte(0),
  name: z.string(),
  tradingCode: z.string(),
  barchartExchangeCode: z.string(),
  specialOptionTradingCode: z.string().optional(),
  miniTradingCode: z.string().optional(),
  uom: z.nativeEnum(UnitOfMeasure),
  standardSize: zDecimal(),
  miniSize: zDecimal().optional(),
  futuresTradingMonths: z.array(z.number().int().positive()),
  newCropMonth: z.number().int().positive(),
  shortDatedCutoff: z.number().int().positive(),

  cqgTradingCode: z.string().optional(),
  cqgShortDatedTradingCode: z.string().optional(),
  cqgStrikeMultiplier: z.number().int().optional(),
  optionStrikePriceStep: zDecimal().optional(),
  shortDatedAndWeeklyOptionsStrikePrice: zDecimal().optional(),
  nearTermMonthDistance: z.number().optional(),
  nearTermOptionsStrikePrice: zDecimal().optional(),
  hasSpecialOptions: z.boolean().optional(),
  barchartStrikeMultiplier: z.number().int(),
  barchartPriceFactor: z.number().int(),

  formatPriceMaxDigitsAfterDecimal: z.number().int(),
  formatOptionsMaxDigitsAfterDecimal: z.number().int(),

  sgLrpCommodity: z.nativeEnum(InsureIQLrpCommodityNames).optional(),
  isLivestock: z.boolean(),
  isTradeable: z.boolean(),
  isMarketingCrop: z.boolean(),
  isHedgingCrop: z.boolean(),
});
export type CommodityInformation = z.infer<typeof CommodityInformation>;

export const COMMODITIES: Record<CommodityId, CommodityInformation> = {
  [CommodityId.UNKNOWN]: {
    id: 0,
    name: 'Other/None',
    tradingCode: '',
    barchartExchangeCode: '',
    uom: UnitOfMeasure.Bushel,
    standardSize: new Decimal(0),
    futuresTradingMonths: [],
    newCropMonth: 0,
    shortDatedCutoff: 0,
    barchartStrikeMultiplier: 0,
    barchartPriceFactor: 100, // price in cents
    formatPriceMaxDigitsAfterDecimal: 2,
    formatOptionsMaxDigitsAfterDecimal: 2,
    isLivestock: false,
    isTradeable: false,
    isMarketingCrop: true,
    isHedgingCrop: true,
  },
  [CommodityId.CORN]: {
    id: CommodityId.CORN,
    name: 'Corn',
    tradingCode: 'ZC',
    barchartExchangeCode: 'CBOT',
    specialOptionTradingCode: 'BC',
    miniTradingCode: 'XN',
    cqgTradingCode: 'ZCE',
    cqgShortDatedTradingCode: 'ZCED',
    optionStrikePriceStep: new Decimal(0.1),
    shortDatedAndWeeklyOptionsStrikePrice: new Decimal(0.05),
    nearTermMonthDistance: 3,
    nearTermOptionsStrikePrice: new Decimal(0.05),
    hasSpecialOptions: true,
    uom: UnitOfMeasure.Bushel,
    standardSize: new Decimal(5000),
    miniSize: new Decimal(1000),
    futuresTradingMonths: [2, 4, 6, 8, 11],
    newCropMonth: 11,
    shortDatedCutoff: 8,
    cqgStrikeMultiplier: 1000,
    barchartStrikeMultiplier: 100,
    barchartPriceFactor: 100, // price in cents
    formatPriceMaxDigitsAfterDecimal: 4, // 1/4  cents
    formatOptionsMaxDigitsAfterDecimal: 5, // 1/8 cents
    isLivestock: false,
    isTradeable: true,
    isMarketingCrop: true,
    isHedgingCrop: true,
  },
  [CommodityId.SOYBEANS]: {
    id: CommodityId.SOYBEANS,
    name: 'Soybeans',
    tradingCode: 'ZS',
    barchartExchangeCode: 'CBOT',
    specialOptionTradingCode: 'BY',
    miniTradingCode: 'XK',
    cqgTradingCode: 'ZSE',
    cqgShortDatedTradingCode: 'ZSED',
    optionStrikePriceStep: new Decimal(0.2),
    shortDatedAndWeeklyOptionsStrikePrice: new Decimal(0.1),
    nearTermMonthDistance: 3,
    nearTermOptionsStrikePrice: new Decimal(0.1),
    hasSpecialOptions: true,
    uom: UnitOfMeasure.Bushel,
    standardSize: new Decimal(5000),
    miniSize: new Decimal(1000),
    futuresTradingMonths: [0, 2, 4, 6, 7, 8, 10],
    newCropMonth: 10,
    shortDatedCutoff: 8,
    cqgStrikeMultiplier: 1000,
    barchartStrikeMultiplier: 100,
    barchartPriceFactor: 100, // price in cents
    formatPriceMaxDigitsAfterDecimal: 4, // 1/4  cents
    formatOptionsMaxDigitsAfterDecimal: 5, // 1/8 cents
    isLivestock: false,
    isTradeable: true,
    isMarketingCrop: true,
    isHedgingCrop: true,
  },
  [CommodityId.KC_WINTER_WHEAT_HRW]: {
    id: CommodityId.KC_WINTER_WHEAT_HRW,
    name: 'KC Winter Wheat HRW',
    tradingCode: 'KE',
    barchartExchangeCode: 'KCBT',
    specialOptionTradingCode: 'BK',
    // miniTradingCode: 'MKC', Can't find this in Barchart
    cqgTradingCode: 'KWE',
    cqgShortDatedTradingCode: 'KWED',
    optionStrikePriceStep: new Decimal(0.1),
    shortDatedAndWeeklyOptionsStrikePrice: new Decimal(0.05),
    nearTermMonthDistance: 3,
    nearTermOptionsStrikePrice: new Decimal(0.05),
    hasSpecialOptions: true,
    uom: UnitOfMeasure.Bushel,
    standardSize: new Decimal(5000),
    miniSize: new Decimal(1000),
    futuresTradingMonths: [2, 4, 6, 8, 11],
    newCropMonth: 6,
    shortDatedCutoff: 4,
    cqgStrikeMultiplier: 1000,
    barchartStrikeMultiplier: 100,
    barchartPriceFactor: 100, // price in cents
    formatPriceMaxDigitsAfterDecimal: 4, // 1/4  cents
    formatOptionsMaxDigitsAfterDecimal: 5, // 1/8 cents
    isLivestock: false,
    isTradeable: true,
    isMarketingCrop: true,
    isHedgingCrop: true,
  },
  [CommodityId.MN_SPRING_WHEAT_HRSW]: {
    id: CommodityId.MN_SPRING_WHEAT_HRSW,
    name: 'MN Spring Wheat HRSW',
    tradingCode: 'MW',
    barchartExchangeCode: 'MGEX',
    cqgTradingCode: 'MWE',
    optionStrikePriceStep: new Decimal(0.1),
    hasSpecialOptions: false,
    uom: UnitOfMeasure.Bushel,
    standardSize: new Decimal(5000),
    futuresTradingMonths: [2, 4, 6, 8, 11],
    newCropMonth: 8,
    shortDatedCutoff: 6,
    cqgStrikeMultiplier: 1000,
    barchartStrikeMultiplier: 100,
    barchartPriceFactor: 100, // price in cents
    formatPriceMaxDigitsAfterDecimal: 4, // 1/4  cents
    formatOptionsMaxDigitsAfterDecimal: 5, // 1/8 cents
    isLivestock: false,
    isTradeable: true,
    isMarketingCrop: true,
    isHedgingCrop: true,
  },
  [CommodityId.CHI_SOFT_RED_WINTER_SRW]: {
    id: CommodityId.CHI_SOFT_RED_WINTER_SRW,
    name: 'CHI Winter Wheat SRW',
    tradingCode: 'ZW',
    barchartExchangeCode: 'CBOT',
    specialOptionTradingCode: 'BH',
    miniTradingCode: 'XW',
    cqgTradingCode: 'ZWA',
    cqgShortDatedTradingCode: 'ZWAD',
    optionStrikePriceStep: new Decimal(0.1),
    shortDatedAndWeeklyOptionsStrikePrice: new Decimal(0.05),
    nearTermMonthDistance: 3,
    nearTermOptionsStrikePrice: new Decimal(0.05),
    hasSpecialOptions: true,
    uom: UnitOfMeasure.Bushel,
    standardSize: new Decimal(5000),
    miniSize: new Decimal(1000),
    futuresTradingMonths: [2, 4, 6, 8, 11],
    newCropMonth: 6,
    shortDatedCutoff: 4,
    cqgStrikeMultiplier: 1000,
    barchartStrikeMultiplier: 100,
    barchartPriceFactor: 100, // price in cents
    formatPriceMaxDigitsAfterDecimal: 4, // 1/4  cents
    formatOptionsMaxDigitsAfterDecimal: 5, // 1/8 cents
    isLivestock: false,
    isTradeable: true,
    isMarketingCrop: true,
    isHedgingCrop: true,
  },
  [CommodityId.OATS]: {
    id: CommodityId.OATS,
    name: 'Oats',
    tradingCode: 'ZO',
    barchartExchangeCode: 'CBOT',
    cqgTradingCode: 'ZOE',
    optionStrikePriceStep: new Decimal(0.05),
    nearTermMonthDistance: 2,
    nearTermOptionsStrikePrice: new Decimal(0.05),
    hasSpecialOptions: false,
    uom: UnitOfMeasure.Bushel,
    standardSize: new Decimal(5000),
    futuresTradingMonths: [2, 4, 6, 8, 11],
    newCropMonth: 8,
    shortDatedCutoff: 6,
    cqgStrikeMultiplier: 1000,
    barchartStrikeMultiplier: 100,
    barchartPriceFactor: 100, // price in cents
    formatPriceMaxDigitsAfterDecimal: 4, // 1/4  cents
    formatOptionsMaxDigitsAfterDecimal: 5, // 1/8 cents
    isLivestock: false,
    isTradeable: true,
    isMarketingCrop: true,
    isHedgingCrop: true,
  },
  [CommodityId.SORGHUM]: {
    id: CommodityId.SORGHUM,
    name: 'Sorghum',
    tradingCode: 'ZC',
    barchartExchangeCode: 'CBOT',
    specialOptionTradingCode: 'BC',
    cqgTradingCode: 'ZCE',
    cqgShortDatedTradingCode: 'ZCED',
    optionStrikePriceStep: new Decimal(0.05),
    nearTermMonthDistance: 3,
    nearTermOptionsStrikePrice: new Decimal(0.05),
    hasSpecialOptions: true,
    uom: UnitOfMeasure.Bushel,
    standardSize: new Decimal(5000),
    futuresTradingMonths: [2, 4, 6, 8, 11],
    newCropMonth: 11,
    shortDatedCutoff: 8,
    cqgStrikeMultiplier: 1000,
    barchartStrikeMultiplier: 100,
    barchartPriceFactor: 100, // price in cents
    formatPriceMaxDigitsAfterDecimal: 4, // 1/4  cents
    formatOptionsMaxDigitsAfterDecimal: 5, // 1/8 cents
    isLivestock: false,
    isTradeable: false,
    isMarketingCrop: true,
    isHedgingCrop: false,
  },
  [CommodityId.ZUC]: {
    id: CommodityId.ZUC,
    name: 'ZUC',
    tradingCode: 'ZUC',
    barchartExchangeCode: '',
    // specialOptionTradingCode: 'BC',
    cqgTradingCode: 'ZUC',
    // cqgShortDatedTradingCode: 'ZCED',
    optionStrikePriceStep: new Decimal(0.05),
    nearTermMonthDistance: 3,
    nearTermOptionsStrikePrice: new Decimal(0.05),
    hasSpecialOptions: false,
    uom: UnitOfMeasure.Bushel,
    standardSize: new Decimal(5000),
    futuresTradingMonths: [2, 4, 6, 8, 11],
    newCropMonth: 11,
    shortDatedCutoff: 8,
    cqgStrikeMultiplier: 1000,
    barchartStrikeMultiplier: 100,
    barchartPriceFactor: 100, // price in cents
    formatPriceMaxDigitsAfterDecimal: 4, // 1/4  cents
    formatOptionsMaxDigitsAfterDecimal: 5, // 1/8 cents
    isLivestock: false,
    isTradeable: false,
    isMarketingCrop: false,
    isHedgingCrop: false,
  },
  [CommodityId.LEAN_HOGS]: {
    id: CommodityId.LEAN_HOGS,
    name: 'Lean Hogs',
    tradingCode: 'HE',
    barchartExchangeCode: 'CME',
    cqgTradingCode: 'HE',
    hasSpecialOptions: false,
    uom: UnitOfMeasure.Pounds,
    standardSize: new Decimal(40000),
    futuresTradingMonths: [1, 3, 4, 5, 6, 7, 9, 11],
    optionStrikePriceStep: new Decimal(0.000125),
    newCropMonth: 1,
    shortDatedCutoff: 11,
    cqgStrikeMultiplier: 1000,
    barchartStrikeMultiplier: 1000,
    barchartPriceFactor: 100, // price in cents
    formatPriceMaxDigitsAfterDecimal: 5, // .025  cents
    formatOptionsMaxDigitsAfterDecimal: 6, // .0125 cents
    sgLrpCommodity: InsureIQLrpCommodityNames.SWINE,
    isLivestock: true,
    isTradeable: true,
    isMarketingCrop: false,
    isHedgingCrop: false,
  },
  [CommodityId.FEEDER_CATTLE]: {
    id: CommodityId.FEEDER_CATTLE,
    name: 'Feeder Cattle',
    tradingCode: 'GF',
    barchartExchangeCode: 'CME',
    cqgTradingCode: 'GF',
    hasSpecialOptions: false,
    uom: UnitOfMeasure.Pounds,
    standardSize: new Decimal(50000),
    futuresTradingMonths: [0, 2, 3, 4, 7, 8, 9, 10],
    optionStrikePriceStep: new Decimal(0.000125),
    newCropMonth: 0,
    shortDatedCutoff: 10,
    cqgStrikeMultiplier: 1000,
    barchartStrikeMultiplier: 1000,
    barchartPriceFactor: 100, // price in cents
    formatPriceMaxDigitsAfterDecimal: 5, // .025  cents
    formatOptionsMaxDigitsAfterDecimal: 6, // .0125 cents
    sgLrpCommodity: InsureIQLrpCommodityNames.FEEDER_CATTLE,
    isLivestock: true,
    isTradeable: true,
    isMarketingCrop: false,
    isHedgingCrop: false,
  },
  [CommodityId.LIVE_CATTLE]: {
    id: CommodityId.LIVE_CATTLE,
    name: 'Live Cattle',
    tradingCode: 'LE',
    barchartExchangeCode: 'CME',
    cqgTradingCode: 'GLE',
    hasSpecialOptions: false,
    uom: UnitOfMeasure.Pounds,
    standardSize: new Decimal(40000),
    futuresTradingMonths: [1, 3, 5, 7, 9, 11],
    optionStrikePriceStep: new Decimal(0.00025),
    newCropMonth: 1,
    shortDatedCutoff: 11,
    cqgStrikeMultiplier: 1000,
    barchartStrikeMultiplier: 1000,
    barchartPriceFactor: 100, // price in cents
    formatPriceMaxDigitsAfterDecimal: 5, // .025  cents
    formatOptionsMaxDigitsAfterDecimal: 6, // .0125 cents
    sgLrpCommodity: InsureIQLrpCommodityNames.FED_CATTLE,
    isLivestock: true,
    isTradeable: true,
    isMarketingCrop: false,
    isHedgingCrop: false,
  },
  [CommodityId.SOYBEAN_MEAL]: {
    id: CommodityId.SOYBEAN_MEAL,
    name: 'Soybean Meal',
    tradingCode: 'ZM',
    barchartExchangeCode: 'CBOT',
    specialOptionTradingCode: 'OMD',
    cqgTradingCode: 'ZME',
    cqgShortDatedTradingCode: 'ZMED',
    hasSpecialOptions: true,
    uom: UnitOfMeasure.ShortTon,
    standardSize: new Decimal(100),
    futuresTradingMonths: [0, 2, 4, 6, 8, 9, 11],
    optionStrikePriceStep: new Decimal(0.05),
    newCropMonth: 11,
    shortDatedCutoff: 9,
    cqgStrikeMultiplier: 10, // dollars not cents
    barchartStrikeMultiplier: 1,
    barchartPriceFactor: 1, // price in dollars
    formatPriceMaxDigitsAfterDecimal: 2, // .10  cents
    formatOptionsMaxDigitsAfterDecimal: 2, // .05 cents
    isLivestock: false,
    isTradeable: true,
    isMarketingCrop: false,
    isHedgingCrop: false,
  },
};

export const insureiqLgmCommodities: [string, ...string[]] = [
  COMMODITIES[CommodityId.CORN].name,
  COMMODITIES[CommodityId.SOYBEAN_MEAL].name,
  COMMODITIES[CommodityId.LIVE_CATTLE].name,
  COMMODITIES[CommodityId.FEEDER_CATTLE].name,
  'Lean Hog', // doesn't end in 's' COMMODITIES[CommodityId.LEAN_HOGS].name
  'Expected Gross Margin',
];

export function hasTradingCode(
  object: CommodityInformation
): object is CommodityInformation {
  // filter off OTHER/NONE
  if ('tradingCode' in object && object['tradingCode']) {
    return true;
  }
  return false;
}

export const CROP_COMMODITIES: CommodityInformation[] = Object.values(
  COMMODITIES
).filter(({ isLivestock }) => !isLivestock);

export const LIVESTOCK_COMMODITIES: CommodityInformation[] = Object.values(
  COMMODITIES
).filter(({ isLivestock }) => isLivestock);

export const TRADEABLE_COMMODITIES: CommodityInformation[] = Object.values(
  COMMODITIES
).filter(({ isTradeable }) => isTradeable);

export const HEDGING_CROPS: CommodityInformation[] = Object.values(
  COMMODITIES
).filter(({ isHedgingCrop }) => isHedgingCrop);

export const MARKETING_CROPS: CommodityInformation[] = Object.values(
  COMMODITIES
).filter(({ isMarketingCrop }) => isMarketingCrop);

export enum CommodityGroup {
  ALL = 'All',
  CROPS = 'Crops',
  LIVESTOCK = 'Livestock',
}
export function getCommodities(
  includeTestingCrops?: boolean,
  type: CommodityGroup = CommodityGroup.ALL
): CommodityInformation[] {
  let commodities = Object.values(COMMODITIES);

  if (type === CommodityGroup.CROPS) {
    commodities = CROP_COMMODITIES;
  }

  if (type === CommodityGroup.LIVESTOCK) {
    commodities = LIVESTOCK_COMMODITIES;
  }

  if (includeTestingCrops) {
    return commodities;
  }

  // Filter off ZUC in prod
  return commodities.filter((commodity) => commodity.id !== CommodityId.ZUC);
}

export const isLivestockCommodity = (commodityId: CommodityId): boolean =>
  LIVESTOCK_COMMODITIES.some((commodity) => commodity.id === commodityId);

export function getTradeableCommodities(): CommodityInformation[] {
  return TRADEABLE_COMMODITIES;
}

export function getCommodityByCommodityId(
  commodityId: CommodityId
): CommodityInformation {
  return COMMODITIES[commodityId] ?? COMMODITIES[CommodityId.UNKNOWN];
}

export function getCommodityByTradingCode(
  tradingCode: string
): CommodityInformation | undefined {
  const upperCode = tradingCode.toUpperCase();
  return Object.values(TRADEABLE_COMMODITIES).find((crop) => {
    return crop.tradingCode === upperCode || crop.miniTradingCode === upperCode;
  });
}

export function isTradingCodeMini(tradingCode: string): boolean {
  const upperCode = tradingCode.toUpperCase();
  return Object.values(TRADEABLE_COMMODITIES).some(
    (crop) => crop.miniTradingCode === upperCode
  );
}

export function getCommodityInformationFromRmaCommodityCode(
  commodityCode: RmaCommodityCodes | string
) {
  switch (commodityCode) {
    case RmaCommodityCodes.FEEDER_CATTLE:
      return COMMODITIES[CommodityId.FEEDER_CATTLE];
    case RmaCommodityCodes.FED_CATTLE:
      return COMMODITIES[CommodityId.LIVE_CATTLE];
    case RmaCommodityCodes.SWINE:
      return COMMODITIES[CommodityId.LEAN_HOGS];

    default:
      throw new Error(commodityCode + ' is not a valid commodity code');
  }
}
