import { FormControl, FormLabel, Typography } from '@mui/material'

export const FieldGroupDetails = ({
  title,
  description,
  children,
  ...props
}) => (
  <FormControl component="fieldset" variant="filled" {...props}>
    <div className="mb1">
      {title && (
        <FormLabel component="legend" className="subheading">
          {title}
        </FormLabel>
      )}
      {description && <Typography variant="p">{description}</Typography>}
    </div>
    {children}
  </FormControl>
)
