import { LivestockInsuranceTypes } from './InsuranceTypes';

export enum AipIds {
  AgriSompo = 'agriSompo',
  ProAg = 'proAg',
  FMH = 'fmh',
  NAU = 'nau',
  Hudson = 'hudson',
  RCIS = 'rcis',
  RH = 'rh',
}

export enum AipNames {
  AgriSompo = 'AgriSompo',
  ProAg = 'ProAg',
  FMH = 'Farmers Mutual Hail',
  NAU = 'NAU Country',
  Hudson = 'Hudson',
  RCIS = 'RCIS',
  RH = 'Rain and Hail',
}

export const AipNameToIdMap = {
  [AipNames.AgriSompo]: AipIds.AgriSompo,
  [AipNames.ProAg]: AipIds.ProAg,
  [AipNames.FMH]: AipIds.FMH,
  [AipNames.NAU]: AipIds.NAU,
  [AipNames.Hudson]: AipIds.Hudson,
  [AipNames.RCIS]: AipIds.RCIS,
  [AipNames.RH]: AipIds.RH,
};

export const AipIdsToNameMap = {
  [AipIds.AgriSompo]: AipNames.AgriSompo,
  [AipIds.ProAg]: AipNames.ProAg,
  [AipIds.FMH]: AipNames.FMH,
  [AipIds.NAU]: AipNames.NAU,
  [AipIds.Hudson]: AipNames.Hudson,
  [AipIds.RCIS]: AipNames.RCIS,
  [AipIds.RH]: AipNames.RH,
};

export type AipInfo = {
  id: AipIds;
  name: AipNames; // Display name used in UI
  isPrimary: boolean; // Flag to indicate this should be the AIP used by default
  isActive: boolean; // Flag to indicate if this AIP is currently active
  canSyncData: boolean; // Flag to indicate that IIQ can sync data from AIP
  agentMgmtLink: string;
  insuranceProducts: {
    [LivestockInsuranceTypes.LRP]?: {
      applicationFilePrefix: string;
      endorsementFilePrefix: string;
    };
    [LivestockInsuranceTypes.LGM]?: {
      cattleApplicationFilePrefix: string;
      swineApplicationFilePrefix: string;
      cattleEndorsementFilePrefix: string;
      swineEndorsementFilePrefix: string;
    };
  };
};

export type ApprovedInsuranceProviders = Record<AipIds, AipInfo>;

export const approvedInsuranceProviders: ApprovedInsuranceProviders = {
  [AipIds.AgriSompo]: {
    id: AipIds.AgriSompo,
    name: AipNames.AgriSompo,
    isPrimary: true,
    isActive: true,
    canSyncData: false,
    agentMgmtLink: 'https://auth.agrinet.agrisompo.com/',
    insuranceProducts: {
      // Indication as to which insurance products this AIP offers
      [LivestockInsuranceTypes.LRP]: {
        // Listing of the application and endorsement file names for each insurance type
        applicationFilePrefix: 'LRP-application',
        endorsementFilePrefix: 'LRP-endorsement',
      },
      [LivestockInsuranceTypes.LGM]: {
        cattleApplicationFilePrefix: 'LGM-cattle-application', // application file includes endorsement
        swineApplicationFilePrefix: 'LGM-swine-application', // application file includes endorsement
        cattleEndorsementFilePrefix: 'LGM-cattle-endorsement', // endorsement file for RY20025
        swineEndorsementFilePrefix: 'LGM-swine-endorsement', // endorsement file for RY20025
      },
    },
  },
  [AipIds.ProAg]: {
    id: AipIds.ProAg,
    name: AipNames.ProAg,
    isPrimary: false,
    isActive: true,
    canSyncData: false,
    agentMgmtLink: 'https://login.proag.com/',
    insuranceProducts: {
      [LivestockInsuranceTypes.LRP]: {
        applicationFilePrefix: 'LRP-application',
        endorsementFilePrefix: 'LRP-endorsement',
      },
    },
  },
  [AipIds.FMH]: {
    id: AipIds.FMH,
    name: AipNames.FMH,
    isPrimary: false,
    isActive: true,
    canSyncData: true,
    agentMgmtLink: 'https://ci.fmh.com/policy/',
    insuranceProducts: {
      [LivestockInsuranceTypes.LRP]: {
        applicationFilePrefix: 'LRP-application',
        endorsementFilePrefix: 'LRP-endorsement',
      },
      [LivestockInsuranceTypes.LGM]: {
        cattleApplicationFilePrefix: 'LGM-cattle-application-coverage',
        swineApplicationFilePrefix: 'LGM-swine-application-coverage',
        cattleEndorsementFilePrefix: 'LGM-cattle-application-coverage',
        swineEndorsementFilePrefix: 'LGM-swine-application-coverage',
      },
    },
  },
  [AipIds.NAU]: {
    id: AipIds.NAU,
    name: AipNames.NAU,
    isPrimary: false,
    isActive: true,
    canSyncData: false,
    agentMgmtLink: 'https://portal.naucountry.com/',
    insuranceProducts: {
      [LivestockInsuranceTypes.LRP]: {
        applicationFilePrefix: 'LRP-application',
        endorsementFilePrefix: 'LRP-endorsement',
      },
    },
  },
  [AipIds.Hudson]: {
    id: AipIds.Hudson,
    name: AipNames.Hudson,
    isPrimary: false,
    isActive: true,
    canSyncData: false,
    agentMgmtLink: 'https://hudsoninsgroup.com/',
    insuranceProducts: {
      [LivestockInsuranceTypes.LRP]: {
        applicationFilePrefix: 'LRP-application',
        endorsementFilePrefix: 'LRP-endorsement',
      },
    },
  },
  [AipIds.RCIS]: {
    id: AipIds.RCIS,
    name: AipNames.RCIS,
    isPrimary: false,
    isActive: true,
    canSyncData: false,
    agentMgmtLink: 'https://www.rcis.com/',
    insuranceProducts: {
      [LivestockInsuranceTypes.LRP]: {
        applicationFilePrefix: 'LRP-application',
        endorsementFilePrefix: 'LRP-endorsement',
      },
      [LivestockInsuranceTypes.LGM]: {
        cattleApplicationFilePrefix: 'LGM-cattle-application-coverage',
        swineApplicationFilePrefix: 'LGM-swine-application-coverage',
        cattleEndorsementFilePrefix: 'LGM-cattle-application-coverage',
        swineEndorsementFilePrefix: 'LGM-swine-application-coverage',
      },
    },
  },
  [AipIds.RH]: {
    id: AipIds.RH,
    name: AipNames.RH,
    isPrimary: false,
    isActive: true,
    canSyncData: false,
    agentMgmtLink: 'https://www.rainhail.com/',
    insuranceProducts: {
      [LivestockInsuranceTypes.LRP]: {
        applicationFilePrefix: 'LRP-application',
        endorsementFilePrefix: 'LRP-endorsement',
      },
    },
  },
} as const;

const [lrpProviders, lgmProviders] = [
  LivestockInsuranceTypes.LRP,
  LivestockInsuranceTypes.LGM,
].map((product) => {
  const aipIds = Object.keys(
    approvedInsuranceProviders
  ) as (keyof typeof approvedInsuranceProviders)[];

  const productProviders: AipInfo[] = aipIds.reduce((acc, provider) => {
    const providerInfo = approvedInsuranceProviders[provider];
    const isActiveProvider =
      providerInfo.isActive && !!providerInfo.insuranceProducts[product];

    if (isActiveProvider) {
      acc.push(providerInfo);
    }
    return acc;
  }, [] as AipInfo[]);
  return productProviders;
});

export const aipByProduct = {
  [LivestockInsuranceTypes.LRP]: lrpProviders,
  [LivestockInsuranceTypes.LGM]: lgmProviders,
} as const;

// primary in general (when no product is specified)
export const primaryAipName = Object.values(approvedInsuranceProviders).find(
  (aip) => aip.isPrimary
)?.name;

// primary aip by product (when product is specified)
export const primaryAipNameByProduct = {
  [LivestockInsuranceTypes.LRP]: approvedInsuranceProviders.proAg.name,
  [LivestockInsuranceTypes.LGM]: approvedInsuranceProviders.agriSompo.name,
} as const;

// get aipKey by name
export const getAipByName = (name: AipNames) => {
  if (!name) {
    return null;
  }
  const aipIds = Object.keys(
    approvedInsuranceProviders
  ) as (keyof typeof approvedInsuranceProviders)[];
  return aipIds.find((key) => approvedInsuranceProviders[key].name === name);
};

// active AIPs
export const activeAips = (
  Object.keys(
    approvedInsuranceProviders
  ) as (keyof typeof approvedInsuranceProviders)[]
).reduce((acc, key) => {
  if (approvedInsuranceProviders[key].isActive) {
    acc.push(approvedInsuranceProviders[key]);
  }
  return acc;
}, [] as AipInfo[]);

export const aipIdsThatCanSync = activeAips
  .filter((aip) => aip.canSyncData)
  .map((aip) => aip.id);

export const parseInsurerName = (
  product: LivestockInsuranceTypes,
  orgAips: AipIds[]
) => {
  const defaultAipName = primaryAipNameByProduct[product];
  const aipsForSelectedProduct = aipByProduct[product];
  const preferredAipId = orgAips[0];
  const preferredAip = aipsForSelectedProduct.find(
    (aip) => aip.id === preferredAipId
  );

  return preferredAip?.name || defaultAipName;
};
