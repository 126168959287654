import {
  isValid,
  format,
  parse,
  differenceInWeeks,
  isDate,
  parseISO,
} from 'date-fns'
import * as dateFnsTz from 'date-fns-tz'
import {
  centralTimezone,
  dateFormat,
  dateFormatShort,
  defaultTimeZone,
  gmt0timezone,
  lgmDateFormatFns,
  longDateTimeFormat,
  lrpDateFormat,
} from './constants.js'

const { utcToZonedTime } = dateFnsTz

export const localization = {
  locale: 'en-US',
  setLocale(locale) {
    this.locale = locale
  },
}
const isEmpty = input => input === null || input === undefined || input === ''
export const zonedDate = (date, timeZone = defaultTimeZone) =>
  utcToZonedTime(isValid(date) ? date : new Date(date), timeZone)

export const shortDateTime = (input, locale = localization.locale) => {
  if (isEmpty(input)) {
    return ''
  }
  const date = isValid(input) ? input : new Date(input)

  const formatter = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })

  return formatter.format(date)
}

export const shortDate = input => {
  if (isEmpty(input)) {
    return ''
  }
  const date = isValid(input) ? input : new Date(input)

  return format(date, dateFormatShort)
}

export const parseDateRange = dateRange =>
  dateRange &&
  Object.entries(dateRange).reduce((acc, [key, val]) => {
    if (key !== 'key') {
      acc[key] = new Date(val)
    }
    return acc
  }, {})

/**
 * Converts the values of a date range object to ISO strings.
 *
 * @module stringifyDateRange
 * @param {Object} dateRange - The date range object to be converted.
 * @returns {Object} An object with the same keys as the input object, but with the values converted to ISO strings.
 * @example
 * const dateRange = { start: new Date(2023, 5, 18), end: new Date(2023, 5, 19) };
 * stringifyDateRange(dateRange);
 * // Returns: { start: '2023-06-18T00:00:00.000Z', end: '2023-06-19T00:00:00.000Z' }
 */
export const stringifyDateRange = dateRange =>
  dateRange &&
  Object.entries(dateRange).reduce((acc, [key, val]) => {
    if (key !== 'key') {
      acc[key] = val.toISOString()
    }
    return acc
  }, {})

export const newZonedDate = date => new Date(zonedDate(date, gmt0timezone))

export const parseMarketingMonth = marketingMonth =>
  parse(marketingMonth, lgmDateFormatFns, new Date())

export const zonedDifferenceInWeeks = (fromDate, toDate) =>
  differenceInWeeks(zonedDate(fromDate), zonedDate(toDate), {
    roundingMethod: 'ceil',
  })

export const getDefaultMarketingDate = (
  salesEffectiveDate,
  targetWeek,
  marketingDates = [],
) => {
  if (!targetWeek) {
    return null
  }
  return marketingDates.find(
    mktDate =>
      zonedDifferenceInWeeks(
        new Date(mktDate),
        new Date(salesEffectiveDate),
      ) === +targetWeek,
  )
}

export const convertTime = ({
  value,
  sourceUnit = 'h',
  targetUnit = 's',
} = {}) => {
  // avoid division by zero
  if (!value || !sourceUnit || !targetUnit) {
    console.trace(
      `Invalid params: { value: %s, sourceUnit: '%s', targetUnit: '%s' }`,
      value,
      sourceUnit,
      targetUnit,
    )
    return null
  }

  const timeUnitsInSeconds = {
    ms: 0.001, // milliseconds
    s: 1, // seconds
    m: 60, // minutes
    h: 3600, // hours
    d: 86400, // days
  }

  const source = timeUnitsInSeconds[sourceUnit]
  const target = timeUnitsInSeconds[targetUnit]

  if (!source || !target) {
    console.trace('Unknown time unit')
    return null
  }

  // Convert source to seconds
  const valueInSeconds = value * source

  // Convert seconds to target unit
  return valueInSeconds / target
}

export const formatZonedDate = (
  date = new Date(),
  tz = gmt0timezone,
  fmt = dateFormat,
) => format(zonedDate(date, tz), fmt)

/**
 * @param {Date} date - The date to check.
 * @returns {boolean} True if the date is in daylight saving time.
 * @note It is useful to check CST and CDT dates.
 */
export const isDaylightSavingTime = (date = new Date()) => {
  if (!isDate(date)) {
    return false
  }
  const jan = new Date(date.getFullYear(), 0, 1)
  const jul = new Date(date.getFullYear(), 6, 1)
  const timezoneOffset = Math.max(
    jan.getTimezoneOffset(),
    jul.getTimezoneOffset(),
  )
  return date.getTimezoneOffset() < timezoneOffset
}

export const formatSlackNotificationDate = (date = new Date()) => {
  const newCentralTimeDate = zonedDate(date, centralTimezone)
  const timezoneStr = isDaylightSavingTime(newCentralTimeDate) ? 'CDT' : 'CST'
  return `${format(newCentralTimeDate, longDateTimeFormat)} ${timezoneStr}`
}

export const formatDateWithLabelFormat = date =>
  format(parseISO(date), lgmDateFormatFns)

export const formatSalesEffectiveDate = sed =>
  format(zonedDate(parseISO(sed), gmt0timezone), lrpDateFormat)

/**
 * Used to decide which pdfs to use for the crop year.
 * @returns {string} The crop year for the pdfs.
 *
 * @example
 * A date of 2023-06-30 (or less than it) will return 2023.
 * A date of 2023-07-01 (or more than it) will return 2024.
 */
export const parseCropYear = salesEffectiveDateStr => {
  if (!salesEffectiveDateStr) {
    return 2024
  }
  const [sedYearStr, sedMonthStr, sedDateStr] = salesEffectiveDateStr.split('-')
  const sedMonth = parseInt(sedMonthStr, 10)
  const sedYear = parseInt(sedYearStr, 10)
  const sedDate = parseInt(sedDateStr, 10)

  const cropYear =
    sedMonth > 7 || (sedMonth === 7 && sedDate > 0) ? sedYear + 1 : sedYear

  return cropYear
}

export const findClosestDate = (
  dateStringsList = [],
  targetDate = new Date(),
) => {
  if (!dateStringsList?.length) {
    return null
  }

  // sort dates in ascending order (so we keep the greatest after the target date) and find the closest date to targetDate
  return dateStringsList
    .sort((prev, curr) => {
      const prevDate = new Date(prev)
      const currDate = new Date(curr)

      return prevDate - currDate
    })
    .reduce((prev, curr) => {
      const prevDiff = Math.abs(new Date(prev) - targetDate)
      const currDiff = Math.abs(new Date(curr) - targetDate)

      return prevDiff < currDiff ? prev : curr
    }, dateStringsList[0])
}
