import { RmaEntityTypeCodes } from '@harvestiq/constants';

export enum BusinessType {
  BureauOfIndianAffairsAndIndianTribeTreaties = 'Bureau Of Indian Affairs And Indian Tribe Treaties',
  Corporation = 'Corporation',
  Estates = 'Estates',
  IndividualOperatingAsABusiness = 'Individual Operating As A Business',
  JointOperations = 'Joint Operations/ventures And Co-ownerships',
  LandlordTenant = 'Landlord/Tenant',
  LimitedLiabilityCompany = 'Limited Liability Company',
  NonProfitOrganizations = 'Non-Profit Or Tax-Exempt Organizations',
  Partnership = 'Partnership',
  PublicSchools = 'Public Schools',
  ReceiverOrLiquidator = 'Receiver or Liquidator',
  StateLocalGovernment = 'State/Local Government',
  TrustIrrevocable = 'Trust - Irrevocable',
  TrustRevocable = 'Trust - Revocable',
}

export const businessTypes = Object.values(BusinessType).filter(
  // Remove the landlord/tenant as it is not present in RMA and should not be an option
  (type) => type !== BusinessType.LandlordTenant
);

export const convertBusinessTypeToRmaEntityTypeCode = (
  type: BusinessType | undefined
) => {
  switch (type) {
    case BusinessType.BureauOfIndianAffairsAndIndianTribeTreaties:
      return RmaEntityTypeCodes.BureauOfIndianAffairsAndIndianTribeTreaties;
    case BusinessType.Corporation:
      return RmaEntityTypeCodes.Corporation;
    case BusinessType.Estates:
      return RmaEntityTypeCodes.Estates;
    case BusinessType.IndividualOperatingAsABusiness:
      return RmaEntityTypeCodes.IndividualOperatingAsABusiness;
    case BusinessType.JointOperations:
      return RmaEntityTypeCodes.JointOperationsVenturesAndCoOwnerships;
    case BusinessType.LandlordTenant:
      // NOTE: BusinessType.LandlordTenant is not present in RMA, coerce to LLC
      return RmaEntityTypeCodes.LimitedLiabilityCompany;
    case BusinessType.LimitedLiabilityCompany:
      return RmaEntityTypeCodes.LimitedLiabilityCompany;
    case BusinessType.NonProfitOrganizations:
      return RmaEntityTypeCodes.NonProfitOrTaxExemptOrganizations;
    case BusinessType.Partnership:
      return RmaEntityTypeCodes.Partnership;
    case BusinessType.PublicSchools:
      return RmaEntityTypeCodes.PublicSchools;
    case BusinessType.ReceiverOrLiquidator:
      return RmaEntityTypeCodes.ReceiverOrLiquidator;
    case BusinessType.StateLocalGovernment:
      return RmaEntityTypeCodes.StateLocalGovernment;
    case BusinessType.TrustIrrevocable:
      return RmaEntityTypeCodes.TrustIrrevocable;
    case BusinessType.TrustRevocable:
      return RmaEntityTypeCodes.TrustRevocable;
    default:
      return null;
  }
};
