import { z } from 'zod';

export const featureFlagsSchema = z.object({
  notificationCrons: z.boolean(),
});

export const orgAppSettingsSchema = z.object({
  id: z.string(),
  domain: z.string(),
  name: z.string(),
  fullLogo: z.string().url(), // url to logo
  senderEmail: z.string().email().nullish(),
  schemaName: z.string(),
  featureFlags: featureFlagsSchema,
  colorScheme: z.object({
    primary: z.string(),
    secondary: z.string(),
    success: z.string(),
    info: z.string(),
    warning: z.string(),
    error: z.string(),
  }),
  email: z.string().email().nullish(),
});

export type OrgAppSettings = z.infer<typeof orgAppSettingsSchema>;

export const allOrgAppSettingsResponseSchema = z.object({
  data: z.array(orgAppSettingsSchema),
});

export type AllOrgAppSettingsResponse = z.infer<
  typeof allOrgAppSettingsResponseSchema
>;
