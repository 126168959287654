import { z } from 'zod';
import { lrpEndorsementSchema } from './lrp-endorsement';
import { lgmEndorsementSchema } from './lgm-endorsement';

export const coverageSchema = z.discriminatedUnion('type', [
  lrpEndorsementSchema,
  lgmEndorsementSchema,
]);

export type Coverage = z.infer<typeof coverageSchema>;
