import { toZod } from 'tozod';
import { z } from 'zod';
import {
  GeneratedAlways,
  Selectable,
  Insertable,
  Updateable,
  Generated,
  ColumnType,
} from 'kysely';
import {
  RmaEntityTypeCodes,
  RmaProgramIndicatorCodes,
  RmaTaxIdTypes,
} from '@harvestiq/constants';
import Decimal from 'decimal.js';
import { zDecimal } from '@harvestiq/zod';
import { expectTypeOf } from 'expect-type';

export interface EntitiesTable {
  id: GeneratedAlways<string>;
  ownerId: string;
  primaryEntityId: string | null;
  aipCode: string | null;
  reinsuranceYear: number | null;
  aipPolicyProducerKey: string | null;
  aipPolicyProducerAddressKey: string | null;
  aipPolicyProducerOtherPersonKey: string | null;
  type: RmaEntityTypeCodes;
  ownershipShare: ColumnType<Decimal, number, number>;
  taxId: string | null;
  taxIdType: RmaTaxIdTypes | null;
  email: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  stateAbbreviation: string | null;
  zip: string | null;
  zipExtensionCode: string | null;
  phone: string | null;
  phoneNumberExceptionCode: string | null;
  phoneExtensionNumber: string | null;
  internationalAddress: string | null;
  internationalCountryCode: string | null;
  internationalPhoneCountryCode: string | null;
  programIndicator: RmaProgramIndicatorCodes | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  suffix: string | null;
  title: string | null;
  picCode: string | null;
  locationStateCode: string | null;
  authorizedRepPoa: string | null;
  stateOfIncorporation: string | null;
  businessName: string | null;
  sourceUri: string | null;
  createdAt: GeneratedAlways<Date>;
  createdBy: string | null;
  updatedAt: Generated<Date>;
  updatedBy: string | null;
  deletedAt: Date | null;
}

export type DbEntity = Selectable<EntitiesTable>;
export const dbEntitySchema: toZod<
  // Complex types not supported by toZod
  Omit<DbEntity, 'type' | 'ownershipShare' | 'taxIdType' | 'programIndicator'>
> = z
  .object({
    id: z.string().uuid(),
    ownerId: z.string().uuid(),
    primaryEntityId: z.string().uuid().nullable(),
    aipCode: z.string().nullable(),
    reinsuranceYear: z.number().nullable(),
    aipPolicyProducerKey: z.string().nullable(),
    aipPolicyProducerAddressKey: z.string().nullable(),
    aipPolicyProducerOtherPersonKey: z.string().nullable(),
    taxId: z.string().nullable(),
    email: z.string().nullable(),
    addressLine1: z.string().nullable(),
    addressLine2: z.string().nullable(),
    city: z.string().nullable(),
    stateAbbreviation: z.string().nullable(),
    zip: z.string().nullable(),
    zipExtensionCode: z.string().nullable(),
    phone: z.string().nullable(),
    phoneNumberExceptionCode: z.string().nullable(),
    phoneExtensionNumber: z.string().nullable(),
    internationalAddress: z.string().nullable(),
    internationalCountryCode: z.string().nullable(),
    internationalPhoneCountryCode: z.string().nullable(),
    firstName: z.string().nullable(),
    middleName: z.string().nullable(),
    lastName: z.string().nullable(),
    suffix: z.string().nullable(),
    title: z.string().nullable(),
    picCode: z.string().nullable(),
    locationStateCode: z.string().nullable(),
    authorizedRepPoa: z.string().nullable(),
    stateOfIncorporation: z.string().nullable(),
    businessName: z.string().nullable(),
    sourceUri: z.string().nullable(),
    createdAt: z.coerce.date(),
    createdBy: z.string().nullable(),
    updatedAt: z.coerce.date(),
    updatedBy: z.string().nullable(),
    deletedAt: z.coerce.date().nullable(),
  })
  .extend({
    type: z.nativeEnum(RmaEntityTypeCodes),
    ownershipShare: zDecimal(),
    taxIdType: z.nativeEnum(RmaTaxIdTypes).nullable(),
    programIndicator: z.nativeEnum(RmaProgramIndicatorCodes).nullable(),
  });

export type DbEntityInsert = Insertable<EntitiesTable>;
export const dbEntityInsertSchema = dbEntitySchema
  .omit({
    id: true,
    createdAt: true,
  })
  .partial()
  .extend({
    ownerId: z.string().uuid(),
    ownershipShare: z.number(),
    type: z.nativeEnum(RmaEntityTypeCodes),
    taxIdType: z.nativeEnum(RmaTaxIdTypes).nullish(),
  });

expectTypeOf<DbEntityInsert>().toMatchTypeOf<
  z.infer<typeof dbEntityInsertSchema>
>();

export type DbEntityUpdate = Updateable<EntitiesTable>;
export const dbEntityUpdate = dbEntitySchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    ownershipShare: z.number(),
  })
  .partial();

expectTypeOf<DbEntityUpdate>().toMatchTypeOf<z.infer<typeof dbEntityUpdate>>();
