import React from 'react'
import { IS_DEV } from 'common'

export function getEnv() {
  if (IS_DEV) return 'local'

  if (window.location.hostname.includes('staging')) return 'staging'
  if (window.location.hostname.includes('test')) return 'test'
  if (window.location.hostname.includes('harvestiq')) return 'production'
  if (window.location.hostname.includes('stockguard')) return 'production'

  return 'local'
}

export function getVersion() {
  if (IS_DEV) return '0.0.1'
  // @ts-expect-error - vite mocks process.env
  if (process.env.REACT_APP_VERSION) return process.env.REACT_APP_VERSION

  return '0.0.1'
}
