import { z } from 'zod';

export const lrpCoverageDetailsSchema = z.object({
  actualEndingValue: z.coerce.number().nullable().optional(),
  actualIndemnity: z.coerce.number().nullable().optional(),
  futuresIndemnity: z.coerce.number().nullable().optional(),
  cmeCashPrice: z.coerce.number().nullable().optional(),
  coverageLevel: z.coerce.number(),
  coveragePrice: z.coerce.number(),
  endDate: z.string(),
  endorsementLength: z.coerce.number().nullable().optional(),
  expectedEndingValue: z.coerce.number(),
  expectedIndemnity: z.coerce.number().nullable().optional(),
  numberofHead: z.coerce.number(), // [sic]
  premium: z.coerce.number(),
  targetWeight: z.coerce.number(),
  price: z
    .object({
      futures: z.object({
        closePrice: z.coerce.number().nullable().optional(),
      }),
      cash: z.object({
        closePrice: z.coerce.number().nullable().optional(),
      }),
    })
    .optional(),

  // m13 ingestion fields
  aip_code: z.string().nullable().optional(),
  reinsurance_year: z.string().nullable().optional(),
  record_type_code: z.string().nullable().optional(),
  aip_policy_producer_key: z.string().nullable().optional(),
  aip_insurance_in_force_key: z.string().nullable().optional(),
  aip_lrp_premium_key: z.string().nullable().optional(),
  aip_insurance_agent_key: z.string().nullable().optional(),
  practice_code: z.string().nullable().optional(),
  commodity_type_code: z.string().nullable().optional(),
  class_code: z.string().nullable().optional(),
  subclass_code: z.string().nullable().optional(),
  intended_use_code: z.string().nullable().optional(),
  irrigation_practice_code: z.string().nullable().optional(),
  cropping_practice_code: z.string().nullable().optional(),
  organic_practice_code: z.string().nullable().optional(),
  interval_code: z.string().nullable().optional(),
  insured_share_percent: z.string().nullable().optional(),
  aip_liability_amount: z.string().nullable().optional(),
  aip_total_premium_amount: z.string().nullable().optional(),
  aip_subsidy_amount: z.string().nullable().optional(),
  additional_subsidy_flag: z.string().nullable().optional(),
  state_private_subsidy_flag: z.string().nullable().optional(),
  settlement_flag: z.string().nullable().optional(),
  cc_subsidy_reduction_percent: z.string().nullable().optional(),
});
export type LrpCoverageDetails = z.infer<typeof lrpCoverageDetailsSchema>;

export const lgmDetailsMarketingMonthsSchema = z.object({
  expectedGrossMargin: z.number().optional(),
  grossMarginExpected: z.number().optional(),
  grossMarginGuarantee: z.coerce.number(),
  marketingMonth: z.string(),
  numberofHead: z.number(), // [sic]
  premium: z.coerce.number(),
  subsidy: z.coerce.number(),
  // Added via calculations
  actualGrossMargin: z.number().optional(),
  actualIndemnity: z.number().optional(),
  expectedIndemnity: z.number().optional(),
});
export type LgmDetailsMarketingMonths = z.infer<
  typeof lgmDetailsMarketingMonthsSchema
>;

export const lgmCoverageDetailsSchema = z.object({
  deductible: z.coerce.number().optional(),
  marketingMonths: z.array(lgmDetailsMarketingMonthsSchema),
});
export type LgmCoverageDetails = z.infer<typeof lgmCoverageDetailsSchema>;

export const coverageDetailsSchema = z.union([
  lrpCoverageDetailsSchema,
  lgmCoverageDetailsSchema,
]);
export type CoverageDetails = z.infer<typeof coverageDetailsSchema>;
