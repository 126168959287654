import { expectTypeOf } from 'expect-type';
import {
  ColumnType,
  GeneratedAlways,
  Insertable,
  Selectable,
  Updateable,
} from 'kysely';
import { z } from 'zod';

export interface DbUsersTable {
  id: GeneratedAlways<string>;
  firstName: string | null;
  lastName: string | null;
  email: string;
  password: string | null;
  middleName: string | null;
  suffix: string | null;
  timezone: string | null;
  confirmedAt: ColumnType<
    Date | null,
    Date | string | null | undefined,
    Date | string | null
  >;
  createdAt: ColumnType<Date, Date | string | undefined, Date | string>;
  updatedAt: ColumnType<Date, Date | string | undefined, Date | string>;
  deletedAt: ColumnType<
    Date | null,
    Date | string | null | undefined,
    Date | string | null
  >;
}

export type DbUser = Selectable<DbUsersTable>;
export type DbUserInsert = Insertable<DbUsersTable>;
export type DbUserUpdate = Updateable<DbUsersTable>;

export const dbUserSchema = z.object({
  id: z.string(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  email: z.string(),
  password: z.string().nullable(),
  middleName: z.string().nullable(),
  suffix: z.string().nullable(),
  timezone: z.string().nullable(),
  confirmedAt: z.coerce.date().nullable(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  deletedAt: z.coerce.date().nullable(),
});

export const dbUserInsertSchema = dbUserSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
});

export const dbUserUpdateSchema = dbUserSchema.omit({
  id: true,
  createdAt: true,
});

// Verify schema type matches kysely
expectTypeOf<DbUser>().toEqualTypeOf<z.infer<typeof dbUserSchema>>();
