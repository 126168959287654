import { Typography } from '@mui/material'
import classNames from 'classnames'
import { BaseLoadingIndicator } from '../LoadingIndicator/BaseLoadingIndicator'

// This component should be used when we do not have appSettings loaded yet
const BaseLoading = ({ title, className, size }) => {
  return (
    <div
      className={classNames(
        'p4 flex flex-col justify-center',
        'items-center width-100',
        className,
      )}
    >
      <BaseLoadingIndicator size={size} />
      {title && (
        <Typography
          className="mt2 color-primary"
          variant="overline"
          display="block"
          gutterBottom
        >
          {title}
        </Typography>
      )}
    </div>
  )
}

export default BaseLoading
