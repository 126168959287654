import { ProgramIndicatorDescriptions } from '../InsuranceTypes';

// See: RMA https://pubfs-rma.fpac.usda.gov/pub/References/insurance_control_elements/PASS/2024/2024_D00154_IceProgramIndicator_YTD.txt
export enum RmaProgramIndicatorCodes {
  // NOTE: RMA contains additional program indicator codes that are not included here as they are not used
  P10BeginningFarmerRancher = 'BF',
  P10VeteranFarmerRancher = 'VF',
}

// Determine the program indicator based on the legacy descriptions
export const getRmaProgramIndicatorCode = (
  applicationTypeOption: ProgramIndicatorDescriptions | undefined
) => {
  let programIndicator = null;
  if (
    applicationTypeOption ===
    ProgramIndicatorDescriptions.BeginningFarmerRancher
  ) {
    programIndicator = RmaProgramIndicatorCodes.P10BeginningFarmerRancher;
  }
  if (
    applicationTypeOption === ProgramIndicatorDescriptions.VeteranFarmerRancher
  ) {
    programIndicator = RmaProgramIndicatorCodes.P10VeteranFarmerRancher;
  }
  return programIndicator;
};
