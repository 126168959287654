import {
  LivestockInsuranceTypes,
  InsureIQPolicyTypes,
} from '@harvestiq/constants';
import { z } from 'zod';
import { dbPolicyInsertSchema } from './db-policy';
import {
  businessPolicyDataSchema,
  individualPolicyDataSchema,
} from './policy-data';

const applicationInsuranceTypeSchema = z.union([
  z.nativeEnum(LivestockInsuranceTypes),
  z.nativeEnum(InsureIQPolicyTypes),
]);

const sharedCreatePolicyReqSchema = dbPolicyInsertSchema
  .pick({
    externalStatus: true,
    insurerName: true,
    insurerPolicyId: true,
    isExternallyManaged: true,
    previousPolicyId: true,
    previousPolicyProvider: true,
    reinsuranceYear: true,
    status: true,
  })
  .extend({
    // FIXME: This should be the type field from DB Schema, requires changes all the way to FE
    applicationFor: applicationInsuranceTypeSchema,
    policyOwnerIds: z.array(z.string()).min(1).optional(),
  })
  .partial({
    // If not provided it is given default in the API handler
    externalStatus: true,
    insurerPolicyId: true,
    isExternallyManaged: true,
    previousPolicyId: true,
    previousPolicyProvider: true,
    reinsuranceYear: true,
    status: true,
  });

// FIXME: These should be discrete schemas not merged together, arises from FE spreading all the data together
const individualCreatePolicyReqSchema = sharedCreatePolicyReqSchema.merge(
  individualPolicyDataSchema
);
const businessCreatePolicyReqSchema = sharedCreatePolicyReqSchema.merge(
  businessPolicyDataSchema
);

export const createPolicyRequestSchema = z.discriminatedUnion(
  'applicationType',
  [individualCreatePolicyReqSchema, businessCreatePolicyReqSchema]
);
export type CreatePolicyRequest = z.infer<typeof createPolicyRequestSchema>;

const applyResponsePolicySchema = z.object({
  type: z.enum([
    LivestockInsuranceTypes.LRP.toUpperCase(),
    LivestockInsuranceTypes.LGM.toUpperCase(),
  ]),
  name: z.string(),
  filename: z.string(),
  policyId: z.string(),
});

export const createPolicyResponseSchema = z.object({
  success: z.boolean(),
  policies: z.array(applyResponsePolicySchema).min(1),
});

export type CreatePolicyResponse = z.infer<typeof createPolicyResponseSchema>;
