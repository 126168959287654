import { useMemo } from 'react'
import { useUser } from '../contexts'

const filterItem = ({
  user,
  item: { children, accessRoles, ...item },
  hasRoles,
}) => {
  if (children) {
    item.children = filterItems({ user, items: children, hasRoles })
  }
  if (accessRoles) {
    if (!user?.isConfirmed) {
      return false
    }

    return hasRoles(accessRoles) && item
  }
  return item
}

const filterItems = ({ user, items, hasRoles }) =>
  items.reduce((acc, item) => {
    const filteredItem = filterItem({ user, item, hasRoles })
    if (filteredItem) {
      acc.push(filteredItem)
    }
    return acc
  }, [])

export const useMenuItems = menuItems => {
  const { user, hasRoles } = useUser()

  return useMemo(
    () => filterItems({ user, items: menuItems, hasRoles }),
    [user, menuItems, hasRoles],
  )
}

export default useMenuItems
