import { Button, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { generateFieldValidators, phoneTypes, validateFields } from 'common'
import { FieldGroups } from '../../components/FieldGroups'
import { updateUser, updateUserProfileByUserId } from '../../http'
import { useModal, useToast, useUser } from '../../contexts'

const profileFieldsGroup = [
  { firstName: { type: 'text' } },
  { lastName: { type: 'text' } },
  { phoneNumber: { type: 'phone' } },
]

const profileRequiredFields = ['firstName', 'lastName', 'phoneNumber']

const profileFieldValidators = generateFieldValidators(profileRequiredFields)

const CompleteProfileModal = ({ user, onUserUpdated }) => {
  const { isUserProfileComplete } = useUser()
  const { closeModal } = useModal()
  const { errorToast, successToast } = useToast()
  const [userInfo, setUserInfo] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    phoneNumber: user?.profile?.primaryPhone,
  })

  const handleChange = ({ target: { name, value } }) => {
    setUserInfo(previousState => ({ ...previousState, [name]: value }))
  }

  const isSubmitDisabled = validateFields(profileFieldValidators, userInfo)

  const showToastError = () => {
    errorToast('Oops, something went wrong. Please try again.')
  }

  useEffect(() => {
    if (isUserProfileComplete) {
      closeModal()
    }
  }, [isUserProfileComplete, closeModal])

  const handleSubmit = async () => {
    if (userInfo.phoneNumber) {
      const { errors: profileErrors } = await updateUserProfileByUserId(
        user.id,
        {
          phones: [
            {
              type: phoneTypes.mobile,
              isPrimary: true,
              number: userInfo.phoneNumber,
            },
          ],
        },
      )
      if (profileErrors) {
        showToastError()
        return
      }
    }

    const {
      data: { user: updatedUser },
      errors: userErrors,
    } = await updateUser(user.id, {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
    })
    if (userErrors) {
      showToastError()
      return
    }

    successToast('Profile successfully updated!')
    onUserUpdated(updatedUser)
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      className="modal p1"
      spacing={4}
    >
      <Grid item>
        <Typography variant="h5">
          Complete your profile to get started
        </Typography>
      </Grid>

      <Grid item>
        <FieldGroups
          state={userInfo}
          fieldGroups={profileFieldsGroup}
          requiredFields={profileRequiredFields}
          onChange={handleChange}
        />
      </Grid>

      <Grid item sx={{ alignSelf: 'center' }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          data-cy="user-profile-form-save-btn"
        >
          Save
        </Button>
      </Grid>
    </Grid>
  )
}

export default CompleteProfileModal
