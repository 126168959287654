import { Chip as MuiChip } from '@mui/material'
import theme, { prettyColor } from '../../styles/theme'

export const Chip = ({ label, color = prettyColor(label), ...restProps }) => (
  <MuiChip
    label={label}
    style={{
      backgroundColor: color,
      color: theme.palette.getContrastText(color),
    }}
    {...restProps}
  />
)

export default Chip
