import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { pages } from 'common'

import classes from './Logo.module.scss'

export const Logo = ({
  className,
  logoUrl,
  imgAlt = '',
  url = pages.home.path,
  size = 32,
}) => {
  const logoClassName = classNames('app-logo', classes.appLogo, className)

  return (
    logoUrl && (
      <Box className={classes.logo} style={{ height: size }}>
        <Link to={url}>
          <img
            className={logoClassName}
            src={logoUrl}
            alt={imgAlt}
            height={size}
          />
        </Link>
      </Box>
    )
  )
}
