import { pages } from 'common'
import { lazy } from 'react'
import MenuRoutes from './components/MenuRoutes'

const Apply = lazy(() => import('./pages/insurance/apply'))
const ApplyType = lazy(() => import('./pages/insurance/apply/[type]'))
const ContactUs = lazy(() => import('./pages/contact-us'))
const Faq = lazy(() => import('./pages/faq'))
const Home = lazy(() => import('./pages/home/home'))
const Lgm = lazy(() => import('./pages/insurance/lgm'))
const LgmQuote = lazy(() => import('./pages/insurance/lgm/LGMQuoter'))
const TermsAndConditions = lazy(() => import('./pages/terms-and-conditions'))
const LRPTerms = lazy(() => import('./pages/terms-and-conditions/lrp'))
const LgmScenarioAnalysis = lazy(() =>
  import('./pages/insurance/lgm/scenario-analysis'),
)
const LrpQuote = lazy(() => import('./pages/insurance/lrp-quote'))
const Media = lazy(() => import('./pages/media/file'))
const MyCoverages = lazy(() => import('./pages/insurance/my-coverages'))
const Profile = lazy(() => import('./pages/profile'))
const Settings = lazy(() => import('./pages/settings'))
const SignIn = lazy(() => import('./pages/account/signin'))
const SignUp = lazy(() => import('./pages/account/signup'))
const SignUpComplete = lazy(() => import('./pages/account/signup-complete'))
const SendLoginLink = lazy(() => import('./pages/account/send-login-link'))
const ForgotPassword = lazy(() => import('./pages/account/forgot-password'))
const ResetPassword = lazy(() => import('./pages/account/reset-password'))
const ConfirmAccount = lazy(() => import('./pages/account/ConfirmAccount'))
const BreakevenCalculator = lazy(() => import('./pages/breakeven-calculator'))
const Watchlist = lazy(() => import('./pages/insurance/watchlist'))
const ApiKeys = lazy(() => import('./pages/api-keys'))
const AgentDashboard = lazy(() => import('./pages/agent/AgentDashboard'))
const AdminPolicyManagement = lazy(() =>
  import('./pages/admin/AdminPolicyManagement'),
)
const AdminCoverageManagement = lazy(() =>
  import('./pages/admin/AdminCoverageManagement'),
)
const Unsubscribe = lazy(() => import('./pages/unsubscribe'))
/* for debugging module not found errors */
const NotFound = lazy(() =>
  import(/* @vite-ignore */ `./pages/${window.__foo ? 'foo' : 'not-found'}`),
)

export const routes = [
  { element: Home, index: true },
  {
    element: Media,
    path: `${pages.media.path}/:file`,
    accessRoles: [],
  },
  { element: Profile, ...pages.profile },
  { element: Settings, ...pages.settings, path: 'settings/*' },
  { element: SendLoginLink, ...pages.sendLoginLink },
  { element: ForgotPassword, ...pages.forgotPassword },
  { element: ResetPassword, ...pages.resetPassword },
  { element: SignIn, ...pages.signIn },
  { element: SignUp, ...pages.signUp },
  { element: SignUpComplete, ...pages.signUpComplete },
  { element: ConfirmAccount, ...pages.confirmAccount },
  { element: TermsAndConditions, ...pages.termsAndConditions },
  { element: LRPTerms, ...pages.lrpTerms },
  {
    element: MenuRoutes,
    isRoot: true,
    children: [
      {
        element: Lgm,
        ...pages.lgm,
        isRoot: true,
        children: [
          {
            element: LgmQuote,
            ...pages.lgmQuote,
          },
          {
            element: LgmScenarioAnalysis,
            ...pages.lgmQuoteScenarioAnalysis,
          },
        ],
      },
      { element: LrpQuote, ...pages.lrpQuote },
      { element: MyCoverages, ...pages.coverage },
      { element: Watchlist, ...pages.watchlist },
      { element: BreakevenCalculator, ...pages.breakevenCalculator },
      { element: Apply, ...pages.apply },
      {
        element: ApplyType,
        path: `${pages.apply.path}/:type`,
        accessRoles: [],
      },
      { element: Faq, ...pages.faq },
      { element: ContactUs, ...pages.contactUs },
    ],
  },
  { element: ApiKeys, ...pages.apiKeys },
  { element: AgentDashboard, ...pages.agentDashboard, path: 'agent/*' },
  { element: AdminPolicyManagement, ...pages.adminPolicyManagement },
  { element: AdminCoverageManagement, ...pages.adminCoverageManagement },
  { element: Unsubscribe, ...pages.unsubscribeCampaign },
  { element: NotFound, title: 'Not Found', path: '/not-found' },
]
