import { Container } from '@mui/material'
import classNames from 'classnames'
import styles from './Main.module.css'

const Main = ({ children, className, ...props }) => (
  <main
    className={classNames('flex flex-1 flex-col py1', styles.main, className)}
    {...props}
  >
    <Container className={styles.container}>{children}</Container>
  </main>
)

export default Main
