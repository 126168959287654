import { RmaTypeCodes } from './TypeCodes';

/**
 *  See: RMA ADM A00430
 */
export enum RmaCommodityTypeCodes {
  // NOTE: RMA contains additional commodity type codes that are not included here as they are not used
  FarrowToFinish = '804',
  Finishing = '805',
  SewPigFinishing = '806',
  CalfFinishing = '807',
  YearlingFinishing = '808',
  Steers = '809',
  Heifers = '810',
  Brahman = '811',
  Dairy = '812',
  SteersAndHeifers = '820',
  NoCommodityTypeSpecified = '997',
}

export function mapRmaTypeCodeToCommodityTypeCode(
  typeCode: RmaTypeCodes
): RmaCommodityTypeCodes {
  switch (typeCode) {
    case RmaTypeCodes.FARROW_TO_FINISH:
      return RmaCommodityTypeCodes.FarrowToFinish;
    case RmaTypeCodes.FINISHING:
      return RmaCommodityTypeCodes.Finishing;
    case RmaTypeCodes.SEW_PIG_FINISHING:
      return RmaCommodityTypeCodes.SewPigFinishing;
    case RmaTypeCodes.CALF_FINISHING:
      return RmaCommodityTypeCodes.CalfFinishing;
    case RmaTypeCodes.YEARLING_FINISHING:
      return RmaCommodityTypeCodes.YearlingFinishing;
    default:
      return RmaCommodityTypeCodes.NoCommodityTypeSpecified;
  }
}
