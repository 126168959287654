import {
  InsureIQLgmCommodityTypeNames,
  RmaLgmCommodityTypeCodes,
  InsureIQCoverageStatus,
  InsureIQPolicyTypes,
  InsureIQLgmCommodityNames,
} from '@harvestiq/constants';
import Decimal from 'decimal.js';
import {
  GeneratedAlways,
  ColumnType,
  Selectable,
  Insertable,
  Updateable,
} from 'kysely';
import {
  LgmCoverageDetails,
  lgmCoverageDetailsSchema,
} from './coverage-details';
import { dbSharedEndorsementSchema } from './shared';
import { z } from 'zod';
import { zDate, zDecimalRequired } from '@harvestiq/zod';

/**
 * Represents an eventual LGM Endorsement table in the database.
 */
export type LgmEndorsementsTable = {
  id: GeneratedAlways<string>;
  agentSignedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  commodity: InsureIQLgmCommodityNames;
  commodityType: InsureIQLgmCommodityTypeNames;
  commodityTypeCode: RmaLgmCommodityTypeCodes | null;
  createdBy: string | null;
  customerSignedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  details: LgmCoverageDetails;
  externalStatus: string | null;
  isExternallyManaged: boolean;
  policyId: string | null;
  salesEffectiveDate: string;
  status: InsureIQCoverageStatus;
  submittedToAipAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  type: InsureIQPolicyTypes;
  watchedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  liveCattleTargetCwtPerHead: ColumnType<
    Decimal | null,
    number | null,
    number | null
  >;
  feederCattleTargetCwtPerHead: ColumnType<
    Decimal | null,
    number | null,
    number | null
  >;
  cornTargetBuPerHead: ColumnType<Decimal | null, number | null, number | null>;
  createdAt: ColumnType<Date, Date | string | undefined, Date | string>;
  updatedAt: ColumnType<Date, Date | string | undefined, Date | string>;
  deletedAt: ColumnType<
    Date | null,
    Date | string | null | undefined,
    Date | string | null
  >;
};

export type DbLgmEndorsement = Selectable<LgmEndorsementsTable>;
export type DbLgmEndorsementInsert = Insertable<LgmEndorsementsTable>;
export type DbLgmEndorsementUpdate = Updateable<LgmEndorsementsTable>;

export const dbLgmEndorsementSchema = dbSharedEndorsementSchema.extend({
  commodity: z.nativeEnum(InsureIQLgmCommodityNames),
  type: z.literal(InsureIQPolicyTypes.LGM),
  commodityType: z.nativeEnum(InsureIQLgmCommodityTypeNames),
  commodityTypeCode: z.nativeEnum(RmaLgmCommodityTypeCodes).nullable(),
  details: lgmCoverageDetailsSchema,
  liveCattleTargetCwtPerHead: zDecimalRequired().nullable(),
  feederCattleTargetCwtPerHead: zDecimalRequired().nullable(),
  cornTargetBuPerHead: zDecimalRequired().nullable(),
});

export const dbLgmEndorsementInsertSchema = dbLgmEndorsementSchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    liveCattleTargetCwtPerHead: z.number().nullable(),
    feederCattleTargetCwtPerHead: z.number().nullable(),
    cornTargetBuPerHead: z.number().nullable(),
    agentSignedAt: zDate().nullable(),
    // Insert as string or date
    customerSignedAt: zDate().nullable(),
    submittedToAipAt: zDate().nullable(),
    watchedAt: zDate().nullable(),
    createdAt: zDate(),
    updatedAt: zDate(),
    deletedAt: zDate().nullable(),
  });

export const dbLgmEndorsementUpdateSchema = dbLgmEndorsementSchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    liveCattleTargetCwtPerHead: z.number().nullable(),
    feederCattleTargetCwtPerHead: z.number().nullable(),
    cornTargetBuPerHead: z.number().nullable(),
    agentSignedAt: zDate().nullable(),
    // Insert as string or date
    customerSignedAt: zDate().nullable(),
    submittedToAipAt: zDate().nullable(),
    watchedAt: zDate().nullable(),
    createdAt: zDate(),
    updatedAt: zDate(),
    deletedAt: zDate().nullable(),
  })
  .partial();
