import { RmaTypeCodes } from '@harvestiq/constants';
import { dayjs, Dayjs } from './dayjs';

/**
 * Takes an optional date as a string, e.g. 2024-04-24, or Date object and returns the associated reinsurance year.
 * If date is not provided uses new Date()
 */
export const getReinsuranceYear = (
  date: string | Date | Dayjs = new Date()
) => {
  let year, monthIndex;

  if (typeof date === 'string') {
    const splitDate = date.split('-').map(Number);
    year = splitDate[0];
    // Months are 0 indexed in JS
    const calendarMonth = splitDate[1];
    monthIndex = calendarMonth - 1;
  }

  if (date instanceof Date) {
    year = date.getFullYear();
    monthIndex = date.getMonth();
  }

  if (dayjs.isDayjs(date)) {
    year = date.year();
    monthIndex = date.month();
  }

  if (monthIndex === undefined || isNaN(monthIndex) || !year) {
    throw new Error('Invalid date');
  }

  // Reinsurance years are from July 1 of previous year to June 30
  // E.g. 2024 reinsurance year runs from July 1, 2023 to June 30, 2024
  // NOTE: Months are 0 indexed in JS, so July is 6
  const reinsuranceYear = monthIndex >= 6 ? year + 1 : year;
  return reinsuranceYear;
};

/**
 * Takes an date as a string, e.g. 2024-04-24, or Date object and returns the associated reinsurance years.
 */
export function getReinsuranceYearsForReleaseDate(
  tgtReleaseDate: string | Date | Dayjs
): Array<number> {
  let year, monthIndex;

  if (typeof tgtReleaseDate === 'string') {
    const splitDate = tgtReleaseDate.split('-').map(Number);
    year = splitDate[0];
    // Months are 0 indexed in JS
    const calendarMonth = splitDate[1];
    monthIndex = calendarMonth - 1;
  }

  if (tgtReleaseDate instanceof Date) {
    year = tgtReleaseDate.getFullYear();
    monthIndex = tgtReleaseDate.getMonth();
  }

  if (dayjs.isDayjs(tgtReleaseDate)) {
    year = tgtReleaseDate.year();
    monthIndex = tgtReleaseDate.month();
  }

  if (monthIndex === undefined || isNaN(monthIndex) || !year) {
    throw new Error('Invalid date');
  }

  // if today is 8/1/2024, then:
  // it is technically the reinsurance year 2025
  // LRP will publish reinsurance year 2024 files from 7/1/2023 to 7/31/2024
  //   and LRP will publish reinsurance year 2025 files from 7/1/2024 to 7/31/2026
  // LGM will publish reinsurance year 2024 files from 7/1/2023 to 7/31/2024
  //   and LGM will publish reinsurance year 2025 files from 7/1/2024 to 7/31/2025

  const reinsuranceYears = [year];
  if (monthIndex > 6) {
    // August - December
    reinsuranceYears.push(year + 1);
  } else if (monthIndex === 6) {
    // July
    reinsuranceYears.push(year + 1);
    reinsuranceYears.push(year - 1);
  } else {
    // January - June
    reinsuranceYears.push(year - 1);
  }

  return reinsuranceYears;
}

/**
 * Returns true if the passed RMA Commodity Type Code is a swine commodity
 */
export const isSwineCommodity = (commodityTypeCode: RmaTypeCodes) => {
  const isSwine =
    commodityTypeCode === RmaTypeCodes.FARROW_TO_FINISH ||
    commodityTypeCode === RmaTypeCodes.SEW_PIG_FINISHING ||
    commodityTypeCode === RmaTypeCodes.FINISHING;
  return isSwine;
};

/**
 * Returns true if the reinsurance year is before 2025 or swine and legacy LGM calculations should be used
 */
export const usePre2025LgmCalculations = ({
  salesEffectiveDate,
  commodityTypeCode,
}: {
  salesEffectiveDate: string | Date | Dayjs;
  commodityTypeCode: RmaTypeCodes;
}) => {
  // Swine retains legacy calculation methods
  const isSwine = isSwineCommodity(commodityTypeCode);
  const reinsuranceYear = getReinsuranceYear(salesEffectiveDate);

  return isSwine || reinsuranceYear < 2025;
};
