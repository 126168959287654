import { Typography, Divider } from '@mui/material'
import { toTitleCase } from 'common'
import classNames from 'classnames'

const Heading = ({
  name,
  variant = 'h6',
  children,
  containerClass,
  enableDivider = true,
  label,
  ...props
}) => {
  return (
    <div className={classNames(containerClass, 'width-100')}>
      <Typography variant={variant} {...props}>
        {children || label || toTitleCase(name)}
      </Typography>
      {enableDivider && <Divider orientation="horizontal" />}
    </div>
  )
}

export default Heading
