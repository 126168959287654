import {
  LgmTargetWeights,
  LgmCattleTargetWeightsByCommodityTypeCode,
  InsureIQLgmCommodityTypeNames,
  InsureIQLgmCommodityTypeCodeByName,
  UnitOfMeasure,
} from '@harvestiq/constants'
import { InputAdornment } from '@mui/material'

export const lgmTargetWeightFields = [
  'liveCattleTargetCwtPerHead',
  'feederCattleTargetCwtPerHead',
  'cornTargetBuPerHead',
]

export const lgmTargetWeightFieldsLabels = {
  liveCattleTargetCwtPerHead: {
    label: LgmTargetWeights.LIVE_CATTLE,
    uom: UnitOfMeasure.HundredWeight,
  },
  feederCattleTargetCwtPerHead: {
    label: LgmTargetWeights.FEEDER_CATTLE,
    uom: UnitOfMeasure.HundredWeight,
  },
  cornTargetBuPerHead: {
    label: LgmTargetWeights.CORN,
    uom: UnitOfMeasure.Bushel,
  },
}

export const getLgmTargetWeightsByCommodityTypeName = commodityTypeName => {
  const isValidCommodityType =
    commodityTypeName === InsureIQLgmCommodityTypeNames.CALF_FINISHING ||
    commodityTypeName === InsureIQLgmCommodityTypeNames.YEARLING_FINISHING

  if (!isValidCommodityType) {
    return null
  }

  const commodityTypeCode =
    InsureIQLgmCommodityTypeCodeByName[commodityTypeName]
  const { liveCattle, feederCattle, corn } =
    LgmCattleTargetWeightsByCommodityTypeCode[commodityTypeCode]

  const liveCattleTarget = {
    min: liveCattle.min.toNumber(),
    max: liveCattle.max.toNumber(),
    default: liveCattle.default.toNumber(),
    uom: liveCattle.uom,
    label: LgmTargetWeights.LIVE_CATTLE,
  }
  const feederCattleTarget = {
    min: feederCattle.min.toNumber(),
    max: feederCattle.max.toNumber(),
    default: feederCattle.default.toNumber(),
    uom: feederCattle.uom,
    label: LgmTargetWeights.FEEDER_CATTLE,
  }
  const cornTarget = {
    min: corn.min.toNumber(),
    max: corn.max.toNumber(),
    default: corn.default.toNumber(),
    uom: corn.uom,
    label: LgmTargetWeights.CORN,
  }

  return {
    liveCattleTarget,
    feederCattleTarget,
    cornTarget,
  }
}

export const getLgmTargetWeightInitialValues = commodityType => {
  const targetWeights = getLgmTargetWeightsByCommodityTypeName(commodityType)
  if (!targetWeights) {
    return {
      liveCattleTargetCwtPerHead: null,
      feederCattleTargetCwtPerHead: null,
      cornTargetBuPerHead: null,
    }
  }
  const { liveCattleTarget, feederCattleTarget, cornTarget } = targetWeights
  return {
    liveCattleTargetCwtPerHead: liveCattleTarget.default,
    feederCattleTargetCwtPerHead: feederCattleTarget.default,
    cornTargetBuPerHead: cornTarget.default,
  }
}

export const getLgmTargetWeightFields = commodityType => {
  const targetWeights = getLgmTargetWeightsByCommodityTypeName(commodityType)
  if (!targetWeights) {
    return []
  }
  const { liveCattleTarget, feederCattleTarget, cornTarget } = targetWeights
  return [
    {
      liveCattleTargetCwtPerHead: {
        type: 'number',
        helperText: `Allowed range: ${liveCattleTarget.min} - ${liveCattleTarget.max} ${liveCattleTarget.uom}`,
        label: liveCattleTarget.label,
        className: 'width-100',
        groupClassNames: 'flex-row width-100 flex flex-1 gap-1 items-start',
        allowDecimals: true,
        decimalPlaces: 2,
        allowNegatives: true,
        min: liveCattleTarget.min,
        max: liveCattleTarget.max,
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              {liveCattleTarget.uom}
            </InputAdornment>
          ),
        },
      },
      feederCattleTargetCwtPerHead: {
        type: 'number',
        helperText: `Allowed range: ${feederCattleTarget.min} - ${feederCattleTarget.max} ${feederCattleTarget.uom}`,
        label: feederCattleTarget.label,
        min: feederCattleTarget.min,
        max: feederCattleTarget.max,
        allowDecimals: true,
        decimalPlaces: 2,
        allowNegatives: true,
        className: 'width-100',
        groupClassNames: 'flex-row width-100 flex flex-1 gap-1 items-start',

        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              {feederCattleTarget.uom}
            </InputAdornment>
          ),
        },
      },
      cornTargetBuPerHead: {
        type: 'number',
        helperText: `Allowed range: ${cornTarget.min} - ${cornTarget.max} ${cornTarget.uom}`,
        label: cornTarget.label,
        min: cornTarget.min,
        max: cornTarget.max,
        allowDecimals: true,
        decimalPlaces: 2,
        allowNegatives: true,
        className: 'width-100',
        groupClassNames: 'flex-row width-100 flex flex-1 gap-1 items-start',

        InputProps: {
          endAdornment: (
            <InputAdornment position="end">{cornTarget.uom}</InputAdornment>
          ),
        },
      },
    },
  ]
}
