import {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { pages, toPascalCase, objsBy, loginUnauthorizedMessage } from 'common'
import { getUser, updateEmail, updateUser } from '../../http'
import { api, localStorage } from '../../utils'
import { useToast } from '../Toast'
import Loading from '../../components/Loading/Loading'
import { useRedirect } from '../Redirect'
import { useReferrerCookie } from '../../hooks/useReferrerCookie'
import { useAppSettings } from '../AppSettingsProvider'
import { useRedirectUrl } from '../../hooks/useRedirectUrl'

const userDefaults = () => ({
  roles: [],
  capabilities: {},
})

export const parseUserData = (userData, allRoles) => {
  const { roles: userRoles = [], events: userEvents = [] } = userData
  const parsedRoles = userRoles.reduce((acc, { capabilities, id }) => {
    acc.roles.push(id)
    acc[`is${toPascalCase(id)}`] = true
    capabilities?.forEach(capability => {
      acc.capabilities[capability] = true
    })
    return acc
  }, userDefaults())

  // If the user is an admin, give them all roles
  if (parsedRoles.isAdmin) {
    allRoles.forEach(roleId => {
      parsedRoles[`is${toPascalCase(roleId)}`] = true
    })
  }

  return { ...userData, ...parsedRoles, events: objsBy(userEvents, 'name') }
}

const isUserProfileComplete = user => {
  return !!(user?.firstName && user?.lastName && user?.profile?.primaryPhone)
}

const UserContext = createContext()
const { Provider } = UserContext

const useProvideUser = () => {
  const navigate = useNavigate()
  const { deleteReferrerCookie } = useReferrerCookie()
  const { errorToast } = useToast()
  const [user, setUserState] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const { _value, redirectOrNavigate } = useRedirect()
  const { roles: allRoles, refetch: refetchAppSettings } = useAppSettings()

  const setUser = useCallback(
    userData => {
      if (!userData) {
        setUserState(null)
        return
      }
      const parsedUser = parseUserData(userData, allRoles)
      setUserState(parsedUser)

      // Referral cookie is only needed for unauthenticated users
      deleteReferrerCookie()
    },
    [deleteReferrerCookie, allRoles],
  )

  const userCan = useCallback(
    permission => !!user?.capabilities?.[permission],
    [user],
  )

  const getMe = useCallback(async () => {
    const resp = await getUser()
    if (resp?.user) {
      setUser(resp?.user)
      return resp?.user
    }
    return null
  }, [setUser])

  const checkForPreviousSession = () => {
    if (!user && !isLoaded) {
      getMe()
        .then(retrievedUser => {
          if (retrievedUser) {
            return redirectOrNavigate()
          }
          return retrievedUser
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          setIsLoaded(true)
        })
    }
  }

  useEffect(checkForPreviousSession, [
    getMe,
    user,
    setIsLoaded,
    isLoaded,
    redirectOrNavigate,
  ])

  const defaultRedirectTo = '/'
  const signIn = useCallback(
    async ({ redirectTo = defaultRedirectTo, ...userData }) => {
      const response = await api.post('/login', userData)
      if (!response || response?.status === 500) {
        errorToast('An error occurred while logging in. Please try again.')
        return
      }

      const { data, status, error } = response
      if (status === 401) {
        errorToast(loginUnauthorizedMessage)
      } else if (error) {
        errorToast(error)
      } else if (data) {
        setUser(data)
        refetchAppSettings()
        const isAgent = data?.roles?.some(role => role.id === 'agent') ?? false
        const redirectUrl =
          isAgent && redirectTo === defaultRedirectTo
            ? pages.agentDashboard.path
            : redirectTo
        redirectOrNavigate(redirectUrl, { replace: true })
      }

      return { data, status, errors: [error].filter(Boolean) }
    },
    [errorToast, redirectOrNavigate, setUser],
  )

  const afterAuthRedirectTo = useRedirectUrl(
    'account/',
    pages.confirmAccount.path,
  )

  const signUp = useCallback(
    async userData => {
      const { email, password } = userData
      const { data, errors } = await api
        .post('/signup', { ...userData, redirectTo: afterAuthRedirectTo })
        .catch(err => console.error(err))
      if (data?.user) {
        return signIn({
          email,
          password,
          redirectTo: pages.signUpComplete.path,
        })
      }
      return { user: data?.user, errors }
    },
    [signIn, afterAuthRedirectTo],
  )

  const hasRole = useCallback(
    roleId => {
      if (!user) {
        return false
      }

      const isSuperAdmin = user.roles.some(role => role === 'superAdmin')

      if (isSuperAdmin) {
        return true // super admin must have access to everything
      }

      if (roleId !== 'superAdmin' && user.isAdmin) {
        return true // if user is an admin, they have access to everything except by super admin places
      }

      return user.roles.some(role => role === roleId)
    },
    [user],
  )

  const hasRoles = useCallback(
    (roles = []) => {
      if (!user) {
        return false
      }

      // empty roles requirement means user just needs to be logged in
      if (!roles.length) {
        return true
      }

      return roles.some(hasRole)
    },
    [hasRole, user],
  )

  return {
    isLoaded,
    user,
    setUser,
    isUserProfileComplete: useMemo(() => isUserProfileComplete(user), [user]),
    updateUser: async userData => {
      const { data, errors } = await updateUser(user.id, userData)
      if (data) {
        setUser(data)
      }
      return { data, errors }
    },
    updateEmail: async email => {
      const { data, errors } = await updateEmail(email)
      if (data) {
        setUser(data.user)
      }
      return { data, errors }
    },
    getUser,
    // @note do not use admin/agent exclusive pages as redirectTo
    sendLoginLink: async ({ email, redirectTo = pages.home.path }) => {
      const { data, errors } = await api
        .post('/auth/send-login-link', {
          email,
          redirectTo,
        })
        .catch(err => console.error(err))
      return { data, errors }
    },
    forgotPassword: async email => {
      const body = { email }
      const { data, errors } = await api
        .post('/forgot-password', body)
        .catch(err => console.error(err))
      return { data, errors }
    },
    resetPassword: async userData => {
      const { data, errors } = await api
        .post('/users/me/reset-password', userData)
        .catch(err => console.error(err))
      return { data, errors }
    },
    sendConfirmationLink: async () => {
      const { data, errors } = await api
        .get('/users/me/send-confirmation', { redirectTo: afterAuthRedirectTo })
        .catch(err => console.error(err))
      return { data, errors }
    },
    signIn,
    signUp,
    signOut: async () => {
      await api.get('/signout')
      setUser(null)
      navigate('/', { replace: true })
      localStorage.clear()
    },
    userCan,
    getMe,
    hasRole,
    hasRoles,
  }
}

export const UserProvider = ({ children }) => {
  const userContext = useProvideUser()
  return (
    <Provider value={userContext}>
      {/* Ensure that children have access to user before rendering them */}
      {userContext.isLoaded ? children : <Loading />}
    </Provider>
  )
}

export const useUser = () => useContext(UserContext)
