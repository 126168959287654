import classNames from 'classnames'
import { usePrevious } from 'react-use'
import { applyFieldGroupExtensions } from 'common'

import FieldGroup from '../FieldGroup'
import { RepeatableFieldGroup } from '../RepeatableFieldGroup'
import { FieldGroupDetails } from '../FieldGroupDetails'

export const FieldGroups = ({
  fieldGroups,
  state,
  onChange,
  requiredFields,
  className,
  errors = {},
  fieldGroupDetails = {},
  fieldGroupExtensions = {},
}) => {
  const prevState = usePrevious(state)
  const extendedFieldGroups = applyFieldGroupExtensions(
    fieldGroups,
    fieldGroupExtensions,
    state,
    prevState,
  )

  return (
    <div className={classNames(className, 'flex flex-1 flex-col gap-2')}>
      {extendedFieldGroups.map((fields, fieldRowIndex) => {
        if (Array.isArray(fields)) {
          const [fieldGroupName, fieldGroupFields, repeatableFieldGroupProps] =
            fields

          const repeatableFieldGroup = (
            <RepeatableFieldGroup
              key={`RepeatableFieldGroup-${fieldGroupName}-${fieldRowIndex}`}
              state={state[fieldGroupName]}
              fieldGroupName={fieldGroupName}
              fieldGroupFields={fieldGroupFields}
              requiredFields={requiredFields}
              onChange={onChange}
              errors={errors[fieldGroupName]}
              {...repeatableFieldGroupProps}
            />
          )

          return fieldGroupDetails[fieldGroupName] ? (
            <FieldGroupDetails
              key={`RepeatableFieldGroupDetails-${fieldGroupName}-${fieldRowIndex}`}
              {...fieldGroupDetails[fieldGroupName]}
            >
              {repeatableFieldGroup}
            </FieldGroupDetails>
          ) : (
            repeatableFieldGroup
          )
        }

        return (
          <FieldGroup
            key={`field-row-${fieldRowIndex}`}
            fields={fields}
            state={state}
            onChange={onChange}
            requiredFields={requiredFields}
            errors={errors}
          />
        )
      })}
    </div>
  )
}

export default FieldGroups
