import { useEffect, useState } from 'react'
import { Box, Button, Collapse, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'
import classNames from 'classnames'

import errorBannerImage from '../../assets/images/error-banner.jpg'
import { Banner } from '../Banner'

import styles from './DefaultFallbackComponent.module.scss'

export const DefaultFallbackComponent = ({
  error,
  componentStack,
  resetError,
}) => {
  const location = useLocation()
  const previousPathname = usePrevious(location.pathname)
  const [showDetails, setShowDetails] = useState(false)

  useEffect(() => {
    if (previousPathname && location.pathname !== previousPathname) {
      resetError()
    }
  }, [location, resetError, previousPathname])

  if (isFailedToFetchModuleError(error)) {
    if (checkShouldRetryReloading()) {
      console.warn('Failed to fetch module, reloading...')
      window.location.reload()
      return <div>Reloading...</div>
    }
  }

  const handleToggleDetails = () => {
    setShowDetails(!showDetails)
  }

  return (
    <Box className="flex flex-1 flex-col">
      <Banner bgImgSrc={errorBannerImage} />
      <Typography variant="h4">Something went wrong:</Typography>
      <pre className={classNames(styles.stackTrace, 'my1')}>
        {error.message}
      </pre>
      <Box className="flex gap-1 justify-center">
        <Button
          variant="contained"
          color="secondary"
          onClick={handleToggleDetails}
        >
          {showDetails ? 'Hide Details' : 'Show Details'}
        </Button>

        <Button onClick={resetError} variant="contained">
          Try again
        </Button>
      </Box>
      <Collapse in={showDetails}>
        <pre
          className={classNames(
            styles.stackTrace,
            'p2 color-red break-word bg-color-shade-10',
          )}
        >
          {componentStack}
        </pre>
      </Collapse>
    </Box>
  )
}

/*
 * This is a workaround for the following issue:
 * https://mitchgavan.com/code-splitting-react-safely/
 * TL;DR; when we deploy a new version of the app and the current user is on the app,
 * they will get a 404 error when clicking Lazy-imported modules.
 */
const isFailedToFetchModuleRegex =
  /Failed to fetch dynamically imported module:(.*)/gi
export function isFailedToFetchModuleError(error) {
  return (
    error && error.message && isFailedToFetchModuleRegex.test(error.message)
  )
}

const IS_RELOADING_KEY = 'insureiq-error-boundary-reloading'
const MAX_RETRIES = 2
function checkShouldRetryReloading() {
  // use localStorage to keep track of retries
  const retries = parseInt(localStorage.getItem(IS_RELOADING_KEY) || '0', 10)
  if (retries >= MAX_RETRIES) {
    localStorage.removeItem(IS_RELOADING_KEY)
    return false
  }
  localStorage.setItem(IS_RELOADING_KEY, (retries + 1).toString())
  return true
}
