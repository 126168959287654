import { CircularProgress, InputAdornment, TextField } from '@mui/material'

export const TextInput = ({ InputProps = {}, isLoading = false, ...props }) => {
  const inputProps = { ...InputProps }

  if (isLoading) {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <CircularProgress />
      </InputAdornment>
    )
  }

  return <TextField InputProps={inputProps} {...props} />
}

export default TextInput
