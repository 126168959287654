import { z } from 'zod';

const roleSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  capabilities: z.array(z.string()),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
  User_Role: z.object({
    id: z.string(),
    userId: z.string(),
    roleId: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  }),
});

export const phoneSchema = z.object({
  type: z.string(),
  number: z.string(),
  isPrimary: z.boolean(),
});

export const profileSchema = z.object({
  ssn: z.string().nullable(),
  primaryPhone: z.string(),
  id: z.string(),
  userId: z.string(),
  maritalStatus: z.string().nullable(),
  liveStock: z.string().nullable(),
  farmerType: z.string().nullable(),
  dob: z.string().datetime().nullable(),
  phones: z.array(phoneSchema),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
});

export const userSchema = z.object({
  isConfirmed: z.boolean(),
  isDisabled: z.boolean(),
  fullName: z.string(),
  id: z.string(),
  firstName: z.string(),
  middleName: z.string().nullable(),
  lastName: z.string(),
  email: z.string().email(),
  confirmedAt: z.string().datetime().nullable(),
  suffix: z.string().nullable(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
  roles: z.array(roleSchema),
  profile: profileSchema,
});
export type User = z.infer<typeof userSchema>;
