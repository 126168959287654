import { createPortal } from 'react-dom'
import { Container } from '@mui/material'
import classNames from 'classnames'

import classes from './Banner.module.scss'

export const Banner = ({ children, bgImgSrc, bgImgColor = 'lightblue' }) =>
  createPortal(
    <div
      style={{
        backgroundColor: bgImgColor,
        backgroundImage: `url(${bgImgSrc})`,
      }}
      className={classNames(
        classes.Banner,
        'flex flex-col items-center justify-end',
      )}
    >
      <Container className="mb2">{children}</Container>
    </div>,
    document.getElementById('banner-portal'),
  )
