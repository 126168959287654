import type { Page } from './Page';

// RevIQ

const livestockPages: { [key: string]: Page } = {
  livestock: {
    title: 'Livestock',
    path: '/livestock',
  },
  livestockLots: {
    title: 'Lots',
    path: '/livestock/lots',
  },
  livestockCreateLot: {
    title: 'Create Lot',
    path: '/livestock/lots/create',
  },
  livestockEditLot: {
    title: 'Edit Lot',
    path: '/livestock/lots/:lotId/edit',
  },
  livestockDeleteLot: {
    title: 'Delete Lot',
    path: '/livestock/lots/:lotId/delete',
  },
  livestockLot: {
    title: 'Lot Details',
    path: '/livestock/lots/details/:lotId',
  },
  livestockLotOverview: {
    title: 'Overview',
    path: '/livestock/lots/details/:lotId/overview',
  },
  livestockLotOwnership: {
    title: 'Ownership',
    path: '/livestock/lots/details/:lotId/ownership',
  },
  livestockLotInsurance: {
    title: 'Insurance',
    path: '/livestock/lots/details/:lotId/insurance',
  },
  livestockLotInsuranceType: {
    title: 'Insurance Type',
    path: '/livestock/lots/details/:lotId/insurance/:type',
  },
  livestockLotInsuranceTypeUnit: {
    title: 'Insurance Unit',
    path: '/livestock/lots/details/:lotId/insurance/:type/:unit',
  },
  livestockLotHedging: {
    title: 'Hedging',
    path: '/livestock/lots/details/:lotId/hegding',
  },
  livestockSales: {
    title: 'Sales',
    path: '/livestock/sales',
  },
  livestockSaleLotSelection: {
    title: 'Sale Lot Selection',
    path: '/livestock/sales/lot-selection',
  },
  livestockCreateSale: {
    title: 'Create Sale',
    path: '/livestock/sales/create',
  },
  livestockEditSale: {
    title: 'Edit Sale',
    path: '/livestock/sales/:saleId/edit',
  },
  livestockDeleteSale: {
    title: 'Delete Sale',
    path: '/livestock/sales/:saleId/delete',
  },
  livestockInsurance: {
    title: 'Insurance',
    path: '/livestock/insurance',
  },
  livestockInsuranceType: {
    title: 'Insurance',
    path: '/livestock/insurance/:type',
  },
  livestockInsuranceTypeUnit: {
    title: 'Insurance',
    path: '/livestock/insurance/:type/:unit',
  },
  livestockCreateLrpCoverage: {
    title: 'Add Coverage',
    path: '/livestock/insurance/lrp/create',
  },
  livestockEditLrpCoverage: {
    title: 'Edit Coverage',
    path: '/livestock/insurance/lrp/:coverageId/edit',
  },
  livestockCreateLgmCoverage: {
    title: 'Add Coverage',
    path: '/livestock/insurance/lgm/create',
  },
  livestockEditLgmCoverage: {
    title: 'Edit Coverage',
    path: '/livestock/insurance/lgm/:coverageId/edit',
  },
  livestockDeleteCoverage: {
    title: 'Delete Coverage',
    path: '/livestock/insurance/:type/:coverageId/delete',
  },
};

const entityPages: { [key: string]: Page } = {
  entity: {
    title: 'Entities',
    path: '/settings/entities',
  },
  entityCreate: {
    title: 'Create Entity',
    path: '/settings/entities/create',
  },
  entityEdit: {
    title: 'Edit Entity',
    path: '/settings/entities/:entityId/edit',
  },
  entityDelete: {
    title: 'Delete Entity',
    path: '/settings/entities/:entityId/delete',
  },
};

const dashboardPages: { [key: string]: Page } = {
  dashboard: {
    title: 'Dashboard',
    path: '/dashboard',
  },
  dashboardCrops: {
    title: 'Crops',
    path: '/dashboard/crops',
  },
  dashboardLivestock: {
    title: 'Livestock',
    path: '/dashboard/livestock',
  },
};

const advisorHubPages: { [key: string]: Page } = {
  advisorHub: {
    title: 'Advisor Hub',
    path: '/advisor-hub',
  },
  advisorHubOperations: {
    title: 'Operations',
    path: '/advisor-hub/operations',
  },
  advisorHubCrops: {
    title: 'Crops',
    path: '/advisor-hub/crops',
  },
  advisorHubReports: {
    title: 'Reports',
    path: '/advisor-hub/reports',
  },
  advisorHubClientCropSummaryReport: {
    title: 'Reports',
    path: '/advisor-hub/reports/client-crop-summary',
  },
};

export const reviqPages: { [key: string]: Page } = {
  ...livestockPages,
  ...entityPages,
  ...dashboardPages,
  ...advisorHubPages,
};

// InsureIQ

export const insureiqPages: { [key: string]: Page } = {
  home: {
    title: 'Home',
    path: '/',
  },
  apply: {
    title: 'Apply for Coverage',
    path: '/insurance/apply',
    accessRoles: [],
  },
  applyBusiness: {
    title: 'Apply for Business Coverage',
    path: '/insurance/apply/business',
    accessRoles: [],
  },
  applyIndividual: {
    title: 'Apply for Individual Coverage',
    path: '/insurance/apply/individual',
    accessRoles: [],
  },
  coverage: {
    title: 'My Coverages',
    path: '/insurance/my-coverages',
    accessRoles: ['agent', 'customer'],
  },
  insurance: {
    title: 'Quotes',
    path: '/insurance',
  },
  lrpQuote: {
    title: 'LRP Quote',
    path: '/insurance/lrp-quote',
  },
  lgm: {
    title: 'LGM Quote',
    path: '/insurance/lgm',
  },
  lgmQuote: {
    title: 'LGM Quote',
    path: '/insurance/lgm/quote',
  },
  lgmQuoteScenarioAnalysis: {
    title: 'Scenario Analysis',
    path: '/insurance/lgm/scenario-analysis',
  },
  media: {
    title: 'Media',
    path: '/media',
  },
  faq: {
    title: 'FAQ',
    path: '/faq',
  },
  contactUs: {
    title: 'Contact Us',
    path: '/contact-us',
  },
  signIn: {
    title: 'Sign In',
    path: '/account/signin',
  },
  signUp: {
    title: 'Sign Up',
    path: '/account/signup',
  },
  signUpComplete: {
    title: 'Confirm Sign Up',
    path: '/account/signup-complete',
  },
  sendLoginLink: {
    title: 'Get Login Link',
    path: '/account/send-login-link',
  },
  forgotPassword: {
    title: 'Forgot Password',
    path: '/account/forgot-password',
  },
  resetPassword: {
    title: 'Reset Password',
    path: '/account/reset-password',
  },
  confirmAccount: {
    title: 'Confirm Account',
    path: '/account/confirm-account',
  },
  profile: {
    title: 'My Profile',
    path: '/profile',
    accessRoles: [],
  },
  settings: {
    title: 'Application Settings',
    path: '/settings',
    accessRoles: ['admin'],
  },
  termsAndConditions: {
    title: 'Terms & Conditions',
    path: '/terms-and-conditions',
  },
  lrpTerms: {
    title: 'Terms & Conditions',
    path: '/terms-and-conditions/lrp',
  },
  breakevenCalculator: {
    title: 'Breakeven Calculator',
    path: '/breakeven-calculator',
  },
  apiKeys: {
    title: 'API Keys',
    path: '/api-keys',
    accessRoles: ['partner'],
  },
  watchlist: {
    title: 'Watchlist',
    path: '/insurance/watchlist',
    accessRoles: ['agent', 'customer'],
  },
  manageUsers: {
    title: 'Manage Users',
    path: '/settings/manage-users',
    accessRoles: ['admin', 'agent'],
  },
  tools: {
    title: 'Tools',
    path: '/settings/tools',
    accessRoles: ['superAdmin'],
  },
  appSettings: {
    title: 'App Settings',
    path: '/settings/app',
    accessRoles: ['admin'],
  },
  aipSettings: {
    title: 'AIP Settings',
    path: '/settings/aip',
    accessRoles: ['admin'],
  },
  import: {
    title: 'Import',
    path: '/settings/import',
    accessRoles: ['admin'],
  },
  agentDashboard: {
    title: 'Agent Hub',
    path: '/agent',
    accessRoles: ['agent'],
  },
  agentCustomers: {
    title: 'Users',
    path: '/agent/customers',
    accessRoles: ['agent'],
  },
  agentPolicyManagement: {
    title: 'Policy Management',
    path: '/agent/policies',
    accessRoles: ['agent'],
  },
  agentCoverageManagement: {
    title: 'Coverage Management',
    path: '/agent/coverages',
    accessRoles: ['agent'],
  },
  agentCampaignManager: {
    title: 'Quote Scheduler (Beta)',
    path: '/agent/campaign-manager',
    accessRoles: ['agent'],
  },
  admin: {
    title: 'Admin',
    path: '/admin/',
    accessRoles: ['admin'],
  },
  adminPolicyManagement: {
    title: 'Policy Management',
    path: '/admin/policies',
    accessRoles: ['agent'],
  },
  adminCoverageManagement: {
    title: 'Coverage Management',
    path: '/admin/coverages',
    accessRoles: ['agent'],
  },
  orgs: {
    title: 'Orgs',
    path: '/settings/orgs',
    accessRoles: ['superAdmin'],
  },
  unsubscribeCampaign: {
    title: 'Unsubscribe',
    path: '/unsubscribe/campaign/:campaignId/:userId',
  },
};
