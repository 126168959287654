import React, { useCallback, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'

import { useUser } from './contexts'
import Layout from './components/Layout/Layout'
import { routes } from './routes'
import ProtectedRoute from './components/ProtectedRoute'
import { useQueryParamNotification } from './hooks/useQueryParamNotification'
import { useReferrerCookie } from './hooks/useReferrerCookie'
import { useModalReminders, useVerifyToken } from './hooks'
import { useFeatureFlags } from './hooks/useFeatureFlag'
import RouteElement from './components/RouteElement/RouteElement'

const checkAccess = ({ user, accessRoles, hasRoles }) => {
  if (accessRoles) {
    if (!user?.isConfirmed) {
      return false
    }
    if (user.isAdmin) {
      return true
    }
    return accessRoles.length ? hasRoles(accessRoles) : true
  }
  return true
}

function App() {
  const { user, hasRoles } = useUser()
  useQueryParamNotification()
  useVerifyToken()
  useModalReminders()

  const { setReferrerCookie } = useReferrerCookie()
  const { setFeatureFlagsFromSearchParams } = useFeatureFlags()

  // Set the referrer cookie once on load
  useEffect(() => {
    setReferrerCookie()
    setFeatureFlagsFromSearchParams()
  }, [])

  const makeRoutes = useCallback(
    (routesToMake, depth = 0) => {
      return routesToMake.map(
        (
          {
            children,
            element,
            accessRoles,
            title,
            isRoot = false,
            ...routeProps
          },
          index,
        ) => {
          const hasAccess = checkAccess({ user, accessRoles, hasRoles })
          const subRoutes = children && makeRoutes(children, depth + 1)

          const Element = hasAccess
            ? element
            : () => <ProtectedRoute user={user} />

          return (
            <Route
              key={`app-route-${index}-${depth}`}
              element={
                <RouteElement title={title} Element={Element} isRoot={isRoot} />
              }
              {...routeProps}
            >
              {subRoutes}
            </Route>
          )
        },
      )
    },
    [user, hasRoles],
  )

  if (user && user.id) {
    datadogRum.setUser({
      id: user.id,
      name: user.fullName,
      email: user.email,
      isAdmin: user.isAdmin,
      isAgent: user.isAgent,
    })
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {makeRoutes(routes)}
      </Route>
    </Routes>
  )
}

export default App
