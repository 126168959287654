import React from 'react'
import { Avatar as MuiAvatar } from '@mui/material'
import { prettyColor } from '../../styles/theme'

const defaultAvatarProp = '/images/avatar.png'

const getInitials = name => {
  const nameArray = name.split(' ')
  const firstName = nameArray.shift()
  const lastName = nameArray.pop()

  const initials = [firstName, lastName].map(n => n?.charAt(0)).join('')

  return initials.toUpperCase()
}

function avatarProps({ fullName, avatarUrl = defaultAvatarProp }) {
  const initials = getInitials(fullName)
  const props = {
    sx: {
      bgcolor: avatarUrl ? 'transparent' : prettyColor(fullName, 'dark'),
      borderRadius: '50%',
    },
    alt: fullName,
    variant: 'rounded',
  }

  props.src = avatarUrl
  props.children = initials

  return props
}

export default function Avatar({ fullName, avatarUrl, ...props }) {
  if (!fullName) return null
  return <MuiAvatar {...props} {...avatarProps({ fullName, avatarUrl })} />
}
