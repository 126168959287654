import {
  RmaClassCodes,
  RmaCommodityCodes,
  RmaCommodityTypeCodes,
  RmaInsurancePlanCodes,
  RmaMarketSymbolCodes,
  RmaSubclassCodes,
  RmaTypeCodes,
} from '@harvestiq/constants';
import { zDate, zDecimalNullable } from '@harvestiq/zod';
import Decimal from 'decimal.js';
import {
  ColumnType,
  Generated,
  GeneratedAlways,
  Insertable,
  Selectable,
  Updateable,
} from 'kysely';
import { z } from 'zod';
import { expectTypeOf } from 'expect-type';

type DecimalColumn = ColumnType<Decimal | null, number | null, number | null>;

/**
 * Types taken from RMA ADM Layout
 * See: https://pubfs-rma.fpac.usda.gov/pub/References/actuarial_data_master/2025/ADMLayout.zip
 */
export interface DbLgmGrossMarginDailyTable {
  id: GeneratedAlways<string>;
  createdAt: GeneratedAlways<Date>;
  updatedAt: Generated<Date>;
  recordTypeCode: string;
  recordCategoryCode: string;
  reinsuranceYear: number;
  commodityYear: number;
  commodityCode: RmaCommodityCodes;
  insurancePlanCode: RmaInsurancePlanCodes;
  stateCode: string;
  countyCode: string;
  typeCode: RmaTypeCodes;
  practiceCode: string;
  marketSymbolCode: RmaMarketSymbolCodes | null;
  commodityTypeCode: RmaCommodityTypeCodes;
  classCode: RmaClassCodes;
  subClassCode: RmaSubclassCodes;
  intendedUseCode: string;
  irrigationPracticeCode: string;
  croppingPracticeCode: string;
  organicPracticeCode: string;
  intervalCode: string;
  month2ExpectedGrossMarginAmount: DecimalColumn;
  month3ExpectedGrossMarginAmount: DecimalColumn;
  month4ExpectedGrossMarginAmount: DecimalColumn;
  month5ExpectedGrossMarginAmount: DecimalColumn;
  month6ExpectedGrossMarginAmount: DecimalColumn;
  month7ExpectedGrossMarginAmount: DecimalColumn;
  month8ExpectedGrossMarginAmount: DecimalColumn;
  month9ExpectedGrossMarginAmount: DecimalColumn;
  month10ExpectedGrossMarginAmount: DecimalColumn;
  month11ExpectedGrossMarginAmount: DecimalColumn;
  month2ActualGrossMarginAmount: DecimalColumn;
  month3ActualGrossMarginAmount: DecimalColumn;
  month4ActualGrossMarginAmount: DecimalColumn;
  month5ActualGrossMarginAmount: DecimalColumn;
  month6ActualGrossMarginAmount: DecimalColumn;
  month7ActualGrossMarginAmount: DecimalColumn;
  month8ActualGrossMarginAmount: DecimalColumn;
  month9ActualGrossMarginAmount: DecimalColumn;
  month10ActualGrossMarginAmount: DecimalColumn;
  month11ActualGrossMarginAmount: DecimalColumn;
  threeDayCmeCwtPrice: DecimalColumn;
  cornEquivalentDefaultValue: DecimalColumn;
  soybeanMealEquivalentDefaultValue: DecimalColumn;
  liabilityPrice: DecimalColumn;
  liabilityMilkPrice: DecimalColumn;
  salesEffectiveDate: string;
  liveCattleEquivalentDefaultValue: DecimalColumn;
  feederCattleEquivalentDefaultValue: DecimalColumn;
  releasedDate: ColumnType<Date | string, Date | string, Date | string | null>;
  lastReleasedDate: ColumnType<
    Date | string | null,
    Date | string | null,
    Date | string | null
  >;
  deletedDate: ColumnType<
    Date | string | null,
    Date | string | null,
    Date | string | null
  >;
  filingDate: ColumnType<Date | string, Date | string, Date | string | null>;
}

export type DbLgmGrossMarginDaily = Selectable<DbLgmGrossMarginDailyTable>;
export type DbLgmGrossMarginDailyInsert =
  Insertable<DbLgmGrossMarginDailyTable>;
export type DbLgmGrossMarginDailyUpdate =
  Updateable<DbLgmGrossMarginDailyTable>;

export const dbLgmGrossMarginDailySchema = z.object({
  id: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  recordTypeCode: z.string(),
  recordCategoryCode: z.string(),
  reinsuranceYear: z.number(),
  commodityYear: z.number(),
  commodityCode: z.nativeEnum(RmaCommodityCodes),
  insurancePlanCode: z.nativeEnum(RmaInsurancePlanCodes),
  stateCode: z.string(),
  countyCode: z.string(),
  typeCode: z.nativeEnum(RmaTypeCodes),
  practiceCode: z.string(),
  marketSymbolCode: z.nativeEnum(RmaMarketSymbolCodes).nullable(),
  commodityTypeCode: z.nativeEnum(RmaCommodityTypeCodes),
  classCode: z.nativeEnum(RmaClassCodes),
  subClassCode: z.nativeEnum(RmaSubclassCodes),
  intendedUseCode: z.string(),
  irrigationPracticeCode: z.string(),
  croppingPracticeCode: z.string(),
  organicPracticeCode: z.string(),
  intervalCode: z.string(),
  month2ExpectedGrossMarginAmount: zDecimalNullable(),
  month3ExpectedGrossMarginAmount: zDecimalNullable(),
  month4ExpectedGrossMarginAmount: zDecimalNullable(),
  month5ExpectedGrossMarginAmount: zDecimalNullable(),
  month6ExpectedGrossMarginAmount: zDecimalNullable(),
  month7ExpectedGrossMarginAmount: zDecimalNullable(),
  month8ExpectedGrossMarginAmount: zDecimalNullable(),
  month9ExpectedGrossMarginAmount: zDecimalNullable(),
  month10ExpectedGrossMarginAmount: zDecimalNullable(),
  month11ExpectedGrossMarginAmount: zDecimalNullable(),
  month2ActualGrossMarginAmount: zDecimalNullable(),
  month3ActualGrossMarginAmount: zDecimalNullable(),
  month4ActualGrossMarginAmount: zDecimalNullable(),
  month5ActualGrossMarginAmount: zDecimalNullable(),
  month6ActualGrossMarginAmount: zDecimalNullable(),
  month7ActualGrossMarginAmount: zDecimalNullable(),
  month8ActualGrossMarginAmount: zDecimalNullable(),
  month9ActualGrossMarginAmount: zDecimalNullable(),
  month10ActualGrossMarginAmount: zDecimalNullable(),
  month11ActualGrossMarginAmount: zDecimalNullable(),
  threeDayCmeCwtPrice: zDecimalNullable(),
  cornEquivalentDefaultValue: zDecimalNullable(),
  soybeanMealEquivalentDefaultValue: zDecimalNullable(),
  liabilityPrice: zDecimalNullable(),
  liveCattleEquivalentDefaultValue: zDecimalNullable(),
  feederCattleEquivalentDefaultValue: zDecimalNullable(),
  liabilityMilkPrice: zDecimalNullable(),
  salesEffectiveDate: z.string(),
  lastReleasedDate: zDate().nullable(),
  releasedDate: zDate(),
  deletedDate: zDate().nullable(),
  filingDate: zDate(),
});

export const dbLgmGrossMarginDailyInsertSchema = dbLgmGrossMarginDailySchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .extend({
    month2ExpectedGrossMarginAmount: z.number().nullable(),
    month3ExpectedGrossMarginAmount: z.number().nullable(),
    month4ExpectedGrossMarginAmount: z.number().nullable(),
    month5ExpectedGrossMarginAmount: z.number().nullable(),
    month6ExpectedGrossMarginAmount: z.number().nullable(),
    month7ExpectedGrossMarginAmount: z.number().nullable(),
    month8ExpectedGrossMarginAmount: z.number().nullable(),
    month9ExpectedGrossMarginAmount: z.number().nullable(),
    month10ExpectedGrossMarginAmount: z.number().nullable(),
    month11ExpectedGrossMarginAmount: z.number().nullable(),
    month2ActualGrossMarginAmount: z.number().nullable(),
    month3ActualGrossMarginAmount: z.number().nullable(),
    month4ActualGrossMarginAmount: z.number().nullable(),
    month5ActualGrossMarginAmount: z.number().nullable(),
    month6ActualGrossMarginAmount: z.number().nullable(),
    month7ActualGrossMarginAmount: z.number().nullable(),
    month8ActualGrossMarginAmount: z.number().nullable(),
    month9ActualGrossMarginAmount: z.number().nullable(),
    month10ActualGrossMarginAmount: z.number().nullable(),
    month11ActualGrossMarginAmount: z.number().nullable(),
  });

export const dbLgmGrossMarginDailyUpdateSchema = dbLgmGrossMarginDailySchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    month2ExpectedGrossMarginAmount: z.number().nullable(),
    month3ExpectedGrossMarginAmount: z.number().nullable(),
    month4ExpectedGrossMarginAmount: z.number().nullable(),
    month5ExpectedGrossMarginAmount: z.number().nullable(),
    month6ExpectedGrossMarginAmount: z.number().nullable(),
    month7ExpectedGrossMarginAmount: z.number().nullable(),
    month8ExpectedGrossMarginAmount: z.number().nullable(),
    month9ExpectedGrossMarginAmount: z.number().nullable(),
    month10ExpectedGrossMarginAmount: z.number().nullable(),
    month11ExpectedGrossMarginAmount: z.number().nullable(),
    month2ActualGrossMarginAmount: z.number().nullable(),
    month3ActualGrossMarginAmount: z.number().nullable(),
    month4ActualGrossMarginAmount: z.number().nullable(),
    month5ActualGrossMarginAmount: z.number().nullable(),
    month6ActualGrossMarginAmount: z.number().nullable(),
    month7ActualGrossMarginAmount: z.number().nullable(),
    month8ActualGrossMarginAmount: z.number().nullable(),
    month9ActualGrossMarginAmount: z.number().nullable(),
    month10ActualGrossMarginAmount: z.number().nullable(),
    month11ActualGrossMarginAmount: z.number().nullable(),
  })
  .partial();

// Verify derived type matches kysely
expectTypeOf<DbLgmGrossMarginDaily>().toEqualTypeOf<
  z.infer<typeof dbLgmGrossMarginDailySchema>
>();
