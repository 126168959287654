import { z } from 'zod';

import { RmaLgmCommodityTypeCodes } from '@harvestiq/constants';
import {
  zDecimal,
  zDecimalNullable,
  zDecimalRequired,
  zIsoDate,
} from '@harvestiq/zod';

// request
export const lgmIndemnityRequestSchema = z.object({
  coverages: z.array(
    z.object({
      commodityTypeCode: z.coerce
        .number()
        .pipe(z.nativeEnum(RmaLgmCommodityTypeCodes)),
      deductible: z.coerce.number().int(),
      referenceId: z.coerce.number().optional(),
      salesEffectiveDate: zIsoDate(),
      marketings: z.array(
        z.object({
          marketingDate: zIsoDate(),
          numberOfHead: z.coerce.number().min(0),
        })
      ),
      liveCattleTargetCwtPerHead: z.coerce.number().optional(),
      feederCattleTargetCwtPerHead: z.coerce.number().optional(),
      cornTargetBuPerHead: z.coerce.number().optional(),
    })
  ),
});

export type LgmIndemnityRequest = z.infer<typeof lgmIndemnityRequestSchema>;

// response
export const lgmIndemnityResponseSchema = z.object({
  data: z.array(
    z.object({
      commodityTypeCode: z
        .number()
        .pipe(z.nativeEnum(RmaLgmCommodityTypeCodes)),
      deductible: z.number().int(),
      referenceId: z.number().optional(),
      salesEffectiveDate: zIsoDate(),
      grossMarginGuaranteesPerHead: zDecimalRequired().array(),

      expectedGrossMarginsPerHead: zDecimalRequired().array(),
      expectedGrossMarginTotals: zDecimalRequired().array(),
      coverageExpectedGrossMarginTotal: zDecimalRequired(),

      actualGrossMarginsTotals: zDecimalNullable().array(),
      actualGrossMarginsPerHead: zDecimalNullable().array(),
      coverageActualGrossMarginTotal: zDecimalRequired(),

      coverageIndemnityPerHead: zDecimalRequired(),
      coverageIndemnityTotal: zDecimalRequired(),
      marketings: z.array(
        z.object({
          marketingDate: zIsoDate(),
          numberOfHead: z.number(),
        })
      ),
    })
  ),
});

export type LgmIndemnityResponse = z.infer<typeof lgmIndemnityResponseSchema>;
