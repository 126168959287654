import isEmail from 'validator/lib/isEmail.js'
import { zonedTimeToUtc } from 'date-fns-tz'
import { getVal } from './object'
import { toTitleCase } from './string'
import { centralTimezone } from './constants.js'

export const isUSAZipCode = str => /^\d{5}(-\d{4})?$/.test(str)

export const validateInput = (shape, validations, all = () => [[true]]) => {
  let hasErrors = false
  const errors = Object.entries(shape).reduce((acc, [key, val]) => {
    const fieldErrors = []
    const criteria = all(key, val, shape)
    const validation = validations[key]

    if (validation) {
      criteria.push(...validation(key, val, shape))
    }

    criteria.forEach(([isValid, errorMessage]) => {
      if (!isValid) {
        fieldErrors.push(
          typeof errorMessage === 'string'
            ? errorMessage
            : errorMessage(fieldErrors),
        )
      }
    })

    acc[key] = fieldErrors.filter(Boolean).join('\n')

    if (acc[key].length) {
      hasErrors = true
    }

    return acc
  }, {})

  return hasErrors && errors
}

export const isPhoneNumber = val => !isNaN(val) && +val.length === 10
export const isRequiredString = val => Boolean(val)
export const isNullOrUndefined = val =>
  val === null || typeof val === 'undefined'
export const isRequired = val => !isNullOrUndefined(val) && val !== ''
export const validators = {
  isRequiredString,
  isRequired,
}

export const generateFieldValidators = (
  requiredFields = [],
  fieldValidators = {},
) => {
  if (typeof requiredFields === 'string') {
    requiredFields = requiredFields.split('|')
  }

  return requiredFields.reduce((acc, fieldName) => {
    if (Array.isArray(acc[fieldName])) {
      if (!acc[fieldName].includes(validators.isRequired)) {
        acc[fieldName].push(validators.isRequired)
      }
    } else if (acc[fieldName]) {
      acc[fieldName] = [acc[fieldName], validators.isRequired]
    } else {
      acc[fieldName] = [validators.isRequired]
    }
    return acc
  }, fieldValidators)
}

const arrChar = '[]'
export const validateFields = (fieldValidators, state) =>
  !Object.entries(fieldValidators).every(([key, validator]) => {
    let fieldName = key
    const isMultiple = key.includes(arrChar)
    if (isMultiple) {
      const arrCharIndex = key.indexOf(arrChar)
      fieldName = key.slice(0, arrCharIndex)
      const keyPath = key.slice(arrCharIndex + arrChar.length)
      return state?.[fieldName]?.every((val, index) => {
        if (keyPath) {
          return (
            val &&
            validateField(
              getVal(`${fieldName}[${index}]${keyPath}`, state),
              validator,
            )
          )
        }
        return validateField(val, validator)
      })
    }

    return validateField(state[fieldName], validator)
  })

const validateField = (value, validator) =>
  Array.isArray(validator)
    ? validator.every(val => val(value))
    : validator(value)

export function missingRequiredFields(fieldNames, data) {
  const missingFields = fieldNames.filter(field => !`${data?.[field]}`.trim())
  if (missingFields.length) {
    const { message, fieldErrors } = missingFields.reduce(
      (acc, fieldName) => {
        const fieldTitle = toTitleCase(fieldName)
        acc.message += `${fieldTitle}, `
        acc.fieldErrors[fieldName] = `${fieldTitle} is a required field`
        return acc
      },
      { message: 'Missing required fields: ', fieldErrors: {} },
    )

    return [message.replace(/,\s$/, '.'), fieldErrors]
  }
}
export const defaultIsRequiredMessage = 'is required.'
export const defaultErrorMessage = 'Please enter a valid '
export const validations = {
  shares: val => [[val >= 10, 'Shares must be equal or greater than 10']],
  ein: val => [[val.length === 9, `${defaultErrorMessage}EIN`]],
  phone: val => [[isPhoneNumber(val), `${defaultErrorMessage}phone number`]],
  phoneNumber: val => validations.phone(val),
  contactPhone: val => validations.phone(val),
  email: val => [[isEmail(val), `${defaultErrorMessage}email`]],
  contactEmail: val => validations.email(val),
  zip: val => [[isUSAZipCode(val), `${defaultErrorMessage}ZIP code`]],
}

export const validateFormInput = (
  state,
  defaultValidations = validations,
  extra = {},
) => {
  const errors = Object.entries(state).reduce((acc, [key, val]) => {
    if (Array.isArray(val)) {
      const fieldErrors = validateGroupFields(val)
      if (fieldErrors.length) {
        acc[key] = fieldErrors
      }
      return acc
    }

    return { ...acc, ...validate(key, val, defaultValidations, extra) }
  }, {})

  return errors
}

const validateGroupFields = fieldGroup => {
  const fieldGroupsErrors = fieldGroup.reduce((a, c) => {
    const fieldGroupErrors = Object.entries(c).reduce(
      (acc, [k, v]) => ({
        ...acc,
        ...validate(k, v),
      }),
      {},
    )
    a.push(fieldGroupErrors)
    return a
  }, [])
  return fieldGroupsErrors
}

const validate = (
  fieldName,
  value,
  allValidations = validations,
  extra = {},
) => {
  const fieldValidations = allValidations[fieldName]
  const errors = {}
  if (fieldValidations) {
    const validation = fieldValidations(value)
    if (extra[fieldName]) {
      validation.push(extra[fieldName])
    }
    validation.forEach(([isValid, errorMessage]) => {
      if (!isValid) {
        errors[fieldName] = errorMessage
      }
    })
    return errors
  }

  return errors
}

export const hasErrors = fieldErrors =>
  Object.values(fieldErrors).some(errors => {
    if (Array.isArray(errors)) {
      return errors.some(obj => Object.keys(obj).length)
    }
    return !!errors
  })

export const insuranceMarketHours = {
  lrp: [8, 0, 0],
  lgm: [9, 0, 0],
}
export const isInsuranceMarketClosed = (type, salesEffectiveDate) => {
  const now = new Date()
  const [hh, min, sec] = insuranceMarketHours[type.toLowerCase()]
  const [yy, mm, dd] = salesEffectiveDate.split('-')
  const marketClosesAt = new Date(yy, mm - 1, dd, hh, min, sec) // set closes at hour
  marketClosesAt.setDate(marketClosesAt.getDate() + 1) // next day
  const zonedMarketClosesAt = new Date(
    zonedTimeToUtc(marketClosesAt, centralTimezone),
  ) // sets market closes at to CT tz, returns local time
  return now.getTime() > zonedMarketClosesAt.getTime()
}

export const hasValidLivestockCapacity = state => {
  if (!state) {
    return false
  }
  return Object.values(state).some(capacity => capacity && capacity > 0)
}
