import { cloneElement, Component } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import {
  DefaultFallbackComponent,
  isFailedToFetchModuleError,
} from './DefaultFallbackComponent'

const defaultErrorBoundaryState = Object.freeze({
  error: null,
  componentStack: null,
})

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = defaultErrorBoundaryState
    this.resetBoundary = this.resetBoundary.bind(this)
  }

  componentDidCatch(error, info) {
    if (isFailedToFetchModuleError(error)) {
      console.warn('Failed to fetch module, reloading...')
    } else {
      // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm

      const renderingError = new Error(error.message)
      renderingError.name = error.name || 'ReactRenderingError'
      renderingError.type = error.type || error.name
      renderingError.source = 'source'
      renderingError.stack = info.componentStack
      renderingError.cause = error

      datadogRum.addError(renderingError)
    }

    this.setState({
      error,
      componentStack: info.componentStack,
    })
  }

  resetBoundary() {
    this.setState(defaultErrorBoundaryState)
    this.props?.onReset?.()
  }

  render() {
    if (this.state.error) {
      return cloneElement(this.props.fallback, {
        ...this.state,
        resetError: this.resetBoundary,
      })
    }

    return this.props.children
  }
}

ErrorBoundary.defaultProps = {
  fallback: <DefaultFallbackComponent />,
}
