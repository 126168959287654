import {
  DbOrderDirection,
  InsureIQCoverageStatus,
  InsureIQPolicyTypes,
  RmaTypeCodes,
} from '@harvestiq/constants';
import { z } from 'zod';
import { dbLrpEndorsementSchema } from './db-lrp-endorsement';
import { dbLgmEndorsementSchema } from './db-lgm-endorsement';

export const getCoveragesRequestSchema = z.object({
  type: z.nativeEnum(InsureIQPolicyTypes).optional(),
  typeCodes: z.array(z.nativeEnum(RmaTypeCodes)).optional(),
  statuses: z.array(z.nativeEnum(InsureIQCoverageStatus)).optional(),
  policyIds: z.array(z.string().uuid()).optional(),
  userOwnerIds: z.array(z.string().uuid()).optional(),
  salesEffectiveDates: z.array(z.string()).optional(),
  skip: z.coerce.number().int().nonnegative().optional(),
  limit: z.coerce.number().int().positive().optional(),
  orderBy: z
    .string()
    // Only allow known coverage fields
    .refine(
      (value: string) =>
        Object.keys(dbLrpEndorsementSchema.shape).includes(value) ||
        Object.keys(dbLgmEndorsementSchema.shape).includes(value),
      {
        message: 'Invalid orderBy field',
      }
    )
    .default('createdAt'),
  order: z.nativeEnum(DbOrderDirection).default(DbOrderDirection.DESC),
});

export type GetCoveragesRequest = z.infer<typeof getCoveragesRequestSchema>;
export type GetCoveragesRequestInput = z.input<
  typeof getCoveragesRequestSchema
>;

export const getCoveragesResponseSchema = z.object({
  coverages: z.array(
    z.discriminatedUnion('type', [
      dbLrpEndorsementSchema,
      dbLgmEndorsementSchema,
    ])
  ),
  totalCount: z.number(),
});

export type GetCoveragesResponse = z.infer<typeof getCoveragesResponseSchema>;

export const getLrpCoveragesReportRequestSchema = getCoveragesResponseSchema;
export type GetLrpCoveragesReportRequest = z.infer<
  typeof getLrpCoveragesReportRequestSchema
>;
