import {
  InsureIQLrpCommodityTypeNames,
  RmaLrpCommodityTypeCodes,
  InsureIQCoverageStatus,
  InsureIQPolicyTypes,
  InsureIQLrpCommodityNames,
} from '@harvestiq/constants';
import {
  GeneratedAlways,
  ColumnType,
  Selectable,
  Insertable,
  Updateable,
} from 'kysely';
import {
  LrpCoverageDetails,
  lrpCoverageDetailsSchema,
} from './coverage-details';
import { dbSharedEndorsementSchema } from './shared';
import { z } from 'zod';
import { zDate } from '@harvestiq/zod';

/**
 * Represents an eventual LRP Endorsement table in the database.
 */
export type LrpEndorsementsTable = {
  id: GeneratedAlways<string>;
  agentSignedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  commodity: InsureIQLrpCommodityNames;
  commodityType: InsureIQLrpCommodityTypeNames;
  commodityTypeCode: RmaLrpCommodityTypeCodes | null;
  createdBy: string | null;
  customerSignedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  details: LrpCoverageDetails;
  externalStatus: string | null;
  isExternallyManaged: boolean;
  policyId: string | null;
  salesEffectiveDate: string;
  status: InsureIQCoverageStatus;
  submittedToAipAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  type: InsureIQPolicyTypes;
  watchedAt: ColumnType<
    Date | null,
    Date | string | null,
    Date | string | null
  >;
  liveCattleTargetCwtPerHead: null;
  feederCattleTargetCwtPerHead: null;
  cornTargetBuPerHead: null;
  createdAt: ColumnType<Date, Date | string | undefined, Date | string>;
  updatedAt: ColumnType<Date, Date | string | undefined, Date | string>;
  deletedAt: ColumnType<
    Date | null,
    Date | string | null | undefined,
    Date | string | null
  >;
};

export type DbLrpEndorsement = Selectable<LrpEndorsementsTable>;
export type DbLrpEndorsementInsert = Insertable<LrpEndorsementsTable>;
export type DbLrpEndorsementUpdate = Updateable<LrpEndorsementsTable>;

export const dbLrpEndorsementSchema = dbSharedEndorsementSchema.extend({
  commodity: z.nativeEnum(InsureIQLrpCommodityNames),
  type: z.literal(InsureIQPolicyTypes.LRP),
  commodityType: z.nativeEnum(InsureIQLrpCommodityTypeNames),
  commodityTypeCode: z.nativeEnum(RmaLrpCommodityTypeCodes).nullable(),
  details: lrpCoverageDetailsSchema,
  // Target weights are always null for LRP
  liveCattleTargetCwtPerHead: z.null(),
  feederCattleTargetCwtPerHead: z.null(),
  cornTargetBuPerHead: z.null(),
});

export const dbLrpEndorsementInsertSchema = dbLrpEndorsementSchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    agentSignedAt: zDate().nullable(),
    // Insert as string or date
    customerSignedAt: zDate().nullable(),
    submittedToAipAt: zDate().nullable(),
    watchedAt: zDate().nullable(),
    createdAt: zDate(),
    updatedAt: zDate(),
    deletedAt: zDate().nullable(),
  });

export const dbLrpEndorsementUpdateSchema = dbLrpEndorsementSchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    agentSignedAt: zDate().nullable(),
    // Insert as string or date
    customerSignedAt: zDate().nullable(),
    submittedToAipAt: zDate().nullable(),
    watchedAt: zDate().nullable(),
    createdAt: zDate(),
    updatedAt: zDate(),
    deletedAt: zDate().nullable(),
  })
  .partial();
