import { expectTypeOf } from 'expect-type';
import { GeneratedAlways, Generated, Selectable } from 'kysely';
import { z } from 'zod';
import { UserProfilePhones, userProfilePhonesSchema } from './phones';

export interface UserProfilesTable {
  id: GeneratedAlways<string>;
  userId: string | null;
  dob: Date | null;
  farmerType: string | null;
  liveStock: string | null;
  maritalStatus: string | null;
  phones: UserProfilePhones;
  ssn: string | null;
  updatedAt: Generated<Date>;
  createdAt: Generated<Date>;
  deletedAt: Date | null;
}

export type DbUserProfile = Selectable<UserProfilesTable>;

export const dbUserProfileSchema = z.object({
  id: z.string().uuid(),
  userId: z.string().nullable(),
  dob: z.date().nullable(),
  farmerType: z.string().nullable(),
  liveStock: z.string().nullable(),
  maritalStatus: z.string().nullable(),
  phones: userProfilePhonesSchema,
  ssn: z.string().nullable(),
  updatedAt: z.date(),
  createdAt: z.date(),
  deletedAt: z.date().nullable(),
});

// Verify derived type matches kysely
expectTypeOf<DbUserProfile>().toEqualTypeOf<
  z.infer<typeof dbUserProfileSchema>
>();
