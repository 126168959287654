import {
  ColumnType,
  GeneratedAlways,
  Insertable,
  Selectable,
  Updateable,
} from 'kysely';
import { Decimal } from 'decimal.js';
import { expectTypeOf } from 'expect-type';
import { z } from 'zod';
import { zDate, zDecimal } from '@harvestiq/zod';
import {
  RmaClassCodes,
  RmaCommodityCodes,
  RmaCommodityTypeCodes,
  RmaInsurancePlanCodes,
  RmaSubclassCodes,
  RmaTypeCodes,
} from '@harvestiq/constants';

type DrawAmountNullable = ColumnType<
  Decimal | null,
  number | null,
  number | null
>;

/**
 * Types taken from RMA ADM Layout
 * See: https://pubfs-rma.fpac.usda.gov/pub/References/actuarial_data_master/2025/ADMLayout.zip
 */
export interface DbLgmDrawDailyTable {
  id: GeneratedAlways<string>;
  createdAt: ColumnType<Date, never, never>;
  updatedAt: ColumnType<Date, never, Date>;
  recordTypeCode: ColumnType<string, string, string>;
  recordCategoryCode: ColumnType<string, string, string>;
  reinsuranceYear: ColumnType<number, number, number>;
  commodityYear: ColumnType<number, number, number>;
  commodityCode: RmaCommodityCodes;
  insurancePlanCode: RmaInsurancePlanCodes;
  stateCode: ColumnType<string, string, string>;
  countyCode: ColumnType<string, string, string>;
  typeCode: RmaTypeCodes;
  practiceCode: ColumnType<string, string, string>;
  marginDrawNumber: ColumnType<number, number, number>;
  commodityTypeCode: RmaCommodityTypeCodes;
  classCode: RmaClassCodes;
  subClassCode: RmaSubclassCodes;
  intendedUseCode: ColumnType<string, string, string>;
  irrigationPracticeCode: ColumnType<string, string, string>;
  croppingPracticeCode: ColumnType<string, string, string>;
  organicPracticeCode: ColumnType<string, string, string>;
  intervalCode: ColumnType<string, string, string>;
  month2MarginDrawAmount: DrawAmountNullable;
  month3MarginDrawAmount: DrawAmountNullable;
  month4MarginDrawAmount: DrawAmountNullable;
  month5MarginDrawAmount: DrawAmountNullable;
  month6MarginDrawAmount: DrawAmountNullable;
  month7MarginDrawAmount: DrawAmountNullable;
  month8MarginDrawAmount: DrawAmountNullable;
  month9MarginDrawAmount: DrawAmountNullable;
  month10MarginDrawAmount: DrawAmountNullable;
  month11MarginDrawAmount: DrawAmountNullable;
  cornMonth2MarginDrawAmount: DrawAmountNullable;
  cornMonth3MarginDrawAmount: DrawAmountNullable;
  cornMonth4MarginDrawAmount: DrawAmountNullable;
  cornMonth5MarginDrawAmount: DrawAmountNullable;
  cornMonth6MarginDrawAmount: DrawAmountNullable;
  cornMonth7MarginDrawAmount: DrawAmountNullable;
  cornMonth8MarginDrawAmount: DrawAmountNullable;
  cornMonth9MarginDrawAmount: DrawAmountNullable;
  cornMonth10MarginDrawAmount: DrawAmountNullable;
  cornMonth11MarginDrawAmount: DrawAmountNullable;
  dairyMonth2MarginDrawAmount: DrawAmountNullable;
  dairyMonth3MarginDrawAmount: DrawAmountNullable;
  dairyMonth4MarginDrawAmount: DrawAmountNullable;
  dairyMonth5MarginDrawAmount: DrawAmountNullable;
  dairyMonth6MarginDrawAmount: DrawAmountNullable;
  dairyMonth7MarginDrawAmount: DrawAmountNullable;
  dairyMonth8MarginDrawAmount: DrawAmountNullable;
  dairyMonth9MarginDrawAmount: DrawAmountNullable;
  dairyMonth10MarginDrawAmount: DrawAmountNullable;
  dairyMonth11MarginDrawAmount: DrawAmountNullable;
  soymMonth2MarginDrawAmount: DrawAmountNullable;
  soymMonth3MarginDrawAmount: DrawAmountNullable;
  soymMonth4MarginDrawAmount: DrawAmountNullable;
  soymMonth5MarginDrawAmount: DrawAmountNullable;
  soymMonth6MarginDrawAmount: DrawAmountNullable;
  soymMonth7MarginDrawAmount: DrawAmountNullable;
  soymMonth8MarginDrawAmount: DrawAmountNullable;
  soymMonth9MarginDrawAmount: DrawAmountNullable;
  soymMonth10MarginDrawAmount: DrawAmountNullable;
  soymMonth11MarginDrawAmount: DrawAmountNullable;
  salesEffectiveDate: ColumnType<Date | string, Date | string, Date | string>;
  liveCattleMonth2MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth3MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth4MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth5MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth6MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth7MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth8MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth9MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth10MarginDrawAmount: DrawAmountNullable;
  liveCattleMonth11MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth2MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth3MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth4MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth5MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth6MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth7MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth8MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth9MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth10MarginDrawAmount: DrawAmountNullable;
  feederCattleMonth11MarginDrawAmount: DrawAmountNullable;
  releasedDate: ColumnType<Date | string, Date | string, Date | string | null>;
  lastReleasedDate: ColumnType<
    Date | string | null,
    Date | string | null,
    Date | string | null
  >;
  deletedDate: ColumnType<
    Date | string | null,
    Date | string | null,
    Date | string | null
  >;
  filingDate: ColumnType<Date | string, Date | string, Date | string | null>;
}

export type DbLgmDrawDaily = Selectable<DbLgmDrawDailyTable>;
export type DbLgmDrawDailyInsert = Insertable<DbLgmDrawDailyTable>;
export type DbLgmDrawDailyUpdate = Updateable<DbLgmDrawDailyTable>;

export const dbLgmDrawDailySchema = z.object({
  id: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
  recordTypeCode: z.string(),
  recordCategoryCode: z.string(),
  reinsuranceYear: z.number(),
  commodityYear: z.number(),
  commodityCode: z.nativeEnum(RmaCommodityCodes),
  insurancePlanCode: z.nativeEnum(RmaInsurancePlanCodes),
  stateCode: z.string(),
  countyCode: z.string(),
  typeCode: z.nativeEnum(RmaTypeCodes),
  practiceCode: z.string(),
  marginDrawNumber: z.number(),
  commodityTypeCode: z.nativeEnum(RmaCommodityTypeCodes),
  classCode: z.nativeEnum(RmaClassCodes),
  subClassCode: z.nativeEnum(RmaSubclassCodes),
  intendedUseCode: z.string(),
  irrigationPracticeCode: z.string(),
  croppingPracticeCode: z.string(),
  organicPracticeCode: z.string(),
  intervalCode: z.string(),
  month2MarginDrawAmount: zDecimal().nullable(),
  month3MarginDrawAmount: zDecimal().nullable(),
  month4MarginDrawAmount: zDecimal().nullable(),
  month5MarginDrawAmount: zDecimal().nullable(),
  month6MarginDrawAmount: zDecimal().nullable(),
  month7MarginDrawAmount: zDecimal().nullable(),
  month8MarginDrawAmount: zDecimal().nullable(),
  month9MarginDrawAmount: zDecimal().nullable(),
  month10MarginDrawAmount: zDecimal().nullable(),
  month11MarginDrawAmount: zDecimal().nullable(),
  cornMonth2MarginDrawAmount: zDecimal().nullable(),
  cornMonth3MarginDrawAmount: zDecimal().nullable(),
  cornMonth4MarginDrawAmount: zDecimal().nullable(),
  cornMonth5MarginDrawAmount: zDecimal().nullable(),
  cornMonth6MarginDrawAmount: zDecimal().nullable(),
  cornMonth7MarginDrawAmount: zDecimal().nullable(),
  cornMonth8MarginDrawAmount: zDecimal().nullable(),
  cornMonth9MarginDrawAmount: zDecimal().nullable(),
  cornMonth10MarginDrawAmount: zDecimal().nullable(),
  cornMonth11MarginDrawAmount: zDecimal().nullable(),
  dairyMonth2MarginDrawAmount: zDecimal().nullable(),
  dairyMonth3MarginDrawAmount: zDecimal().nullable(),
  dairyMonth4MarginDrawAmount: zDecimal().nullable(),
  dairyMonth5MarginDrawAmount: zDecimal().nullable(),
  dairyMonth6MarginDrawAmount: zDecimal().nullable(),
  dairyMonth7MarginDrawAmount: zDecimal().nullable(),
  dairyMonth8MarginDrawAmount: zDecimal().nullable(),
  dairyMonth9MarginDrawAmount: zDecimal().nullable(),
  dairyMonth10MarginDrawAmount: zDecimal().nullable(),
  dairyMonth11MarginDrawAmount: zDecimal().nullable(),
  soymMonth2MarginDrawAmount: zDecimal().nullable(),
  soymMonth3MarginDrawAmount: zDecimal().nullable(),
  soymMonth4MarginDrawAmount: zDecimal().nullable(),
  soymMonth5MarginDrawAmount: zDecimal().nullable(),
  soymMonth6MarginDrawAmount: zDecimal().nullable(),
  soymMonth7MarginDrawAmount: zDecimal().nullable(),
  soymMonth8MarginDrawAmount: zDecimal().nullable(),
  soymMonth9MarginDrawAmount: zDecimal().nullable(),
  soymMonth10MarginDrawAmount: zDecimal().nullable(),
  soymMonth11MarginDrawAmount: zDecimal().nullable(),
  salesEffectiveDate: zDate(),
  liveCattleMonth2MarginDrawAmount: zDecimal().nullable(),
  liveCattleMonth3MarginDrawAmount: zDecimal().nullable(),
  liveCattleMonth4MarginDrawAmount: zDecimal().nullable(),
  liveCattleMonth5MarginDrawAmount: zDecimal().nullable(),
  liveCattleMonth6MarginDrawAmount: zDecimal().nullable(),
  liveCattleMonth7MarginDrawAmount: zDecimal().nullable(),
  liveCattleMonth8MarginDrawAmount: zDecimal().nullable(),
  liveCattleMonth9MarginDrawAmount: zDecimal().nullable(),
  liveCattleMonth10MarginDrawAmount: zDecimal().nullable(),
  liveCattleMonth11MarginDrawAmount: zDecimal().nullable(),
  feederCattleMonth2MarginDrawAmount: zDecimal().nullable(),
  feederCattleMonth3MarginDrawAmount: zDecimal().nullable(),
  feederCattleMonth4MarginDrawAmount: zDecimal().nullable(),
  feederCattleMonth5MarginDrawAmount: zDecimal().nullable(),
  feederCattleMonth6MarginDrawAmount: zDecimal().nullable(),
  feederCattleMonth7MarginDrawAmount: zDecimal().nullable(),
  feederCattleMonth8MarginDrawAmount: zDecimal().nullable(),
  feederCattleMonth9MarginDrawAmount: zDecimal().nullable(),
  feederCattleMonth10MarginDrawAmount: zDecimal().nullable(),
  feederCattleMonth11MarginDrawAmount: zDecimal().nullable(),
  lastReleasedDate: zDate().nullable(),
  releasedDate: zDate(),
  deletedDate: zDate().nullable(),
  filingDate: zDate(),
});

export const dbLgmDrawDailyInsertSchema = dbLgmDrawDailySchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
});

export const dbLgmDrawDailyUpdateSchema = dbLgmDrawDailySchema
  .omit({
    id: true,
    createdAt: true,
  })
  .partial();

// Verify derived type matches kysely
expectTypeOf<DbLgmDrawDaily>().toEqualTypeOf<
  z.infer<typeof dbLgmDrawDailySchema>
>();
