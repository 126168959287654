import pluralize from 'pluralize'

import { toCamelCase } from '../string'
import { modelNames } from '../constants.js'

const permissions = 'view|list|create|edit|delete'.split('|')
const generatePermissions = type => {
  const upperType = type.charAt(0).toUpperCase() + type.slice(1)
  return permissions.map(permission => `${permission}${pluralize(upperType)}`)
}

const level = (typePermissions, limit) => typePermissions.slice(0, limit)
const { appSetting, user, policy } = Object.values(modelNames).reduce(
  (acc, cur) => {
    const modelName = toCamelCase(cur)
    return { ...acc, [modelName]: generatePermissions(modelName) }
  },
  {},
)

export const admin = {
  title: 'Administrator',
  id: 'admin',
  description:
    'Can view, create, modify or delete anything including settings within the app',
  capabilities: [...appSetting, ...user, ...policy],
}

export const broker = {
  title: 'Broker',
  id: 'broker',
  description: 'Can view, create, modify or delete customer and policy data',
  capabilities: [...user, ...policy],
}

export const customer = {
  title: 'Customer',
  id: 'customer',
  description:
    'Can view, create, modify or delete own customer and policy content',
  capabilities: [...level(user, 2), ...level(policy, 4)],
}

export const agent = {
  id: 'agent',
  title: 'Agent',
  description: 'Specify that the user is an agent',
  capabilities: [...user, ...policy],
}

export const systemAgent = {
  id: 'systemAgent',
  title: 'SystemAgent',
  description:
    'SystemAgent is the active agents and is responsible for the agent actions, like sending emails',
  capabilities: [...user, ...policy],
}

export const roleConfigs = [admin, broker, customer, agent, systemAgent]
