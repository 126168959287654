import { CampaignFrequency } from './Campaigns';
import { RmaCommodityCodes } from './rma/CommodityCodes';

export enum InsuranceType {
  YIELD = 0,
  REVENUE = 1,
  REVENUE_HPE = 2,
}

export enum ApplicationTypes {
  INDIVIDUAL = 'individual',
  BUSINESS = 'business',
}

export enum ProgramIndicatorDescriptions {
  BeginningFarmerRancher = 'Beginning Farmer/Rancher',
  VeteranFarmerRancher = 'Veteran Farmer/Rancher',
  Neither = 'Neither',
}

export enum InsureIQCommodityNames {
  FEEDER_CATTLE = 'Feeder Cattle',
  FED_CATTLE = 'Fed Cattle',
  SWINE = 'Swine',
  CATTLE = 'Cattle',
}

export const SupportedRmaCommodityCodes = [
  RmaCommodityCodes.FEEDER_CATTLE,
  RmaCommodityCodes.FED_CATTLE,
  RmaCommodityCodes.CATTLE,
  RmaCommodityCodes.SWINE,
];

// TODO: Get rid of one of these enums
// lowercase for URL param purposes
export enum LivestockInsuranceTypes {
  LRP = 'lrp',
  LGM = 'lgm',
}

export enum InsureIQPolicyTypes {
  LRP = 'LRP',
  LGM = 'LGM',
}

// TODO: Clean up the need for this conversion function
export const convertToCapitalizedLivestockInsTypes = (
  type: InsureIQPolicyTypes | LivestockInsuranceTypes
) => {
  if (
    type === InsureIQPolicyTypes.LRP ||
    type === LivestockInsuranceTypes.LRP
  ) {
    return InsureIQPolicyTypes.LRP;
  } else {
    return InsureIQPolicyTypes.LGM;
  }
};

export enum LivestockInsuranceTypeLabels {
  LRP = 'Livestock Risk Protection (LRP)',
  LGM = 'Livestock Gross Margin (LGM)',
}

// lowercase for URL param purposes
export enum LivestockInsuranceUnits {
  CWT = 'cwt',
  HEAD = 'head',
  TOTAL = 'total',
}

export const LivestockInsuranceOptions = [
  {
    value: LivestockInsuranceTypes.LRP,
    label: LivestockInsuranceTypeLabels.LRP,
  },
  {
    value: LivestockInsuranceTypes.LGM,
    label: LivestockInsuranceTypeLabels.LGM,
  },
];

export const LgmUnits = [
  {
    value: LivestockInsuranceUnits.HEAD,
    label: 'Head',
  },
  {
    value: LivestockInsuranceUnits.TOTAL,
    label: 'Total',
  },
];

export const LrpUnits = [
  {
    value: LivestockInsuranceUnits.CWT,
    label: 'CWT',
  },
  ...LgmUnits,
];

export const LivestockUnitsOptionsByType = {
  [LivestockInsuranceTypes.LGM]: LgmUnits,
  [LivestockInsuranceTypes.LRP]: LrpUnits,
};

export const CampignFrequenciesByType = {
  [LivestockInsuranceTypes.LGM]: [
    CampaignFrequency.WEEKLY,
    CampaignFrequency.MONTHLY,
  ],
  [LivestockInsuranceTypes.LRP]: [
    CampaignFrequency.DAILY,
    CampaignFrequency.WEEKLY,
    CampaignFrequency.MONTHLY,
  ],
};
