import { Tooltip } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import classes from './InfoTooltip.module.scss'

export const InfoTooltip = ({ children, title, ...props }) => (
  <Tooltip title={title} placement="top-start" {...props}>
    <div className={classes.tooltip}>
      <InfoOutlined className="body" />
      {children}
    </div>
  </Tooltip>
)
